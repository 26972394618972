import React, { useState } from 'react'
import "./defaultLayout.css"
import Index from '../../Index';
import Header from './Header';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';

const DefaultLayout = () => {
    const [open, setOpen] = useState(true);
    return (
      <Index.Box className="admin-main">
      <Index.Box className={`admin-main-left ${open ? "active" : "deactive"}`}>
        <Sidebar open={open} setOpen={setOpen} />
      </Index.Box>
      <Index.Box className={`admin-main-right ${open ? "active" : "deactive"}`}>
        <Header setOpen={setOpen} open={open} />
        <Index.Box className="admin-content-main">
          <Outlet />
        </Index.Box>
      </Index.Box>
    </Index.Box>
    );
  };


export default DefaultLayout