import React, { useEffect, useState } from "react";
import "./user.css";
import Index from "../../../Index";
import { PagesIndex } from "../../../PagesIndex";

const UserList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  const getUserData = async () => {
    setLoading(true);
    try {
      const res = await PagesIndex.userListData(page + 1, rowsPerPage);
      if (res?.data?.status === 200) {
        setData(res?.data?.data);
        setFilterData(res?.data?.data);
        setTotal(res?.data?.pagination.total);
      }
    } catch (error) {
      console.log(error, "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserData();
  }, [page, rowsPerPage]);

  const handleSearchChange = (e) => {
    const search = e.target.value.trim();
    const res = data?.filter((item) => {
      const createdAtString = item.createdAt
        ? PagesIndex.format(new Date(item?.createdAt), "dd/MM/yyyy")
        : "";
      return (
        (item.userWalletAddress &&
          item.userWalletAddress?.toLowerCase()?.includes(search?.toLowerCase())) ||
        createdAtString.includes(search) 
      );
    });
    setFilterData(res);
  };

  return (
    <>
      <Index.Box className="presale-userlist">
        <Index.Box className="user-heading-box">
          <Index.Box className="user-heading">User Management</Index.Box>
          <Index.TextField
            size="small"
            placeholder="Search"
            className="user-search-input"
            onChange={handleSearchChange}
          />
        </Index.Box>

        <Index.TableContainer className="user-list-table-container">
          <Index.Table className="user-list-top-table">
            <Index.TableHead
              className="private-pre-table-head"
              stickyHeader
              aria-label="sticky table"
            >
              <Index.TableRow>
                <Index.TableCell align="center">S.No</Index.TableCell>
                <Index.TableCell>User Wallet Address</Index.TableCell>
           
                <Index.TableCell>Register Date</Index.TableCell>
       

                {/* <Index.TableCell>View</Index.TableCell> */}
              </Index.TableRow>
            </Index.TableHead>
            {/* {!loading ? ( */}
              <Index.TableBody>
               { filterData?.length > 0?
               <>
                {filterData?.map((item, index) => (
                  <Index.TableRow key={index}>
                    <Index.TableCell align="center">
                      {page * rowsPerPage + index + 1}
                    </Index.TableCell>
      
                    <Index.TableCell>{item?.userWalletAddress}</Index.TableCell>
                    <Index.TableCell>
                      
                     {new Date(item?.createdAt)?.toLocaleString("en-US", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </Index.TableCell>

                    
                    {/* <Index.TableCell
                      className="view-icon-box"
                      onClick={() => {}}
                    >
                      <Index.VisibilityIcon className="vew-icon" />
                    </Index.TableCell> */}
                  </Index.TableRow>
                ))}
                </>:
                 <Index.TableRow>
                 <Index.TableCell
                   colSpan={10}
                   align="center"
                   className="no-data-cell"
                 >
                   No user found....
                 </Index.TableCell>
               </Index.TableRow>
                 }
              </Index.TableBody>
            {/* ) : ("")} */}
          </Index.Table>
        </Index.TableContainer>

        <Index.TablePagination
        className="table-list-pagination"
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Index.Box>
    </>
  );
};

export default UserList;
