import React, { useState } from "react";
import "./profile.css";
import Index from "../../../Index";
import { PagesIndex } from "../../../PagesIndex";

const EditProfile = () => {
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const adminProfileData = PagesIndex.useSelector((state) => state?.data?.admin?.profile);
  const dispatch = PagesIndex.useDispatch();

  const initialValues = {
    email: adminProfileData ? adminProfileData?.email : "",
    name: adminProfileData ? adminProfileData?.name : "",
    mobileNumber: adminProfileData ? adminProfileData?.mobile_number : "",
    profile: adminProfileData ? adminProfileData?.profile_image : "",
  };

  const handleProfileChange = (e) => {
    if (e.target.files.length !== 0) {
      setProfile(e.target.files[0]);
    }
  };

  const userProfileFormSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("mobile_number", values.mobileNumber);
    if (profile) {
      formData.append("image", profile);
    }

    try {
      const res = await PagesIndex?.adminProfileUpdate(formData);
      if (res?.data?.status === 200) {
      dispatch(PagesIndex?.adminProfileDetail());

      }
    } catch (error) {}
    setLoading(false);
  };

  return (
    <Index.Box className="admin-edit-profile-main">
      <Index.Box className="admin-edit-profile-box">
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={userProfileFormSubmit}
          initialValues={initialValues}
          validationSchema={PagesIndex.AdminProfileSchema}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
          }) => (
            <form
              component="form"
              spacing={2}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Index.Box className="edit-profile-heading">
                <Index.Box>
                  <Index.Typography className="heading-text">
                    Edit Profile
                  </Index.Typography>
                  <Index.Box className="heading-profile-image">
                    <img
                      src={
                        profile
                          ? URL.createObjectURL(profile)
                          : adminProfileData?.profile_image
                          ? `${PagesIndex?.ImageURL}${adminProfileData?.profile_image}`
                          : Index.Jpg.dummyImg
                      }
                      className="heading-profile"
                      alt="Profile"
                    />
                    

                    <Index.Box className="upload-image">
                      <Index.Button
                        variant="contained"
                        component="label"
                        className="file-upload-btn"
                      >
                        <PagesIndex.LightTooltip title="Select image">
                        <img
                          src={Index.Png.profileIcon}
                          className="upload-icon-img"
                          alt="upload img"
                        />
                        </PagesIndex.LightTooltip>
                        <input
                      accept="image/jpeg, image/jpg, image/png"
                          hidden
                          className="file-input"
                          id="profile-image-upload"
                          type="file"
                          onChange={(e) => {
                            const file = e?.target?.files[0];
                            if (file) {
                              const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
                              if (allowedTypes.includes(file.type)) {
                                setFieldValue("profile", file);
                                handleProfileChange(e);
                              } else {
                                console.error("File type not allowed. Please select a JPG, JPEG, or PNG file.");
                              }
                            }
                          }}
                        />
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
         
                </Index.Box>

              </Index.Box>
              <Index.Box className="error-box">
                {touched.profile && errors.profile && (
          <span className="error-message">{errors.profile}</span>
           )}
          </Index.Box>
              <Index.Box>
                <Index.Box className="edit-profile-content">
                  <Index.Box className="auth-body">
                    <Index.Grid container spacing={3}>
                      <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                        <Index.Box className="form-group-prof">
                          <Index.Typography className="form-lable-prof">
                            Name*
                          </Index.Typography>
                          <Index.TextField
                            fullWidth
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Name"
                            size="small"
                            variant="filled"
                            className="form-input login-password profile-input-field"
                            name="name"
                            data-testid="fullName-input"
                            autoComplete="off"
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            inputProps={{maxLength:32}}
                            value={values?.name}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                                setFieldValue("name", inputValue);
                              }
                            }}
                            
                            helperText={touched.name && errors.name}
                            error={Boolean(errors.name && touched.name)}
                          />
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                        <Index.Box className="form-group-prof">
                          <Index.Typography className="form-lable-prof">
                            Email*
                          </Index.Typography>
                          <Index.TextField
                            fullWidth
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Email"
                            size="small"
                            variant="filled"
                            className="form-input login-password profile-input-field"
                            name="email"
                            data-testid="email-input"
                            autoComplete="off"
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            value={values?.email}
                            inputProps={{maxLength:64}}
                            onChange={(e) => {
                              setFieldValue("email", e.target.value.trim());
                            }}
                            helperText={touched.email && errors.email}
                            error={Boolean(errors.email && touched.email)}
                          />
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                        <Index.Box className="form-group-prof">
                          <Index.Typography className="form-lable-prof">
                            Number*
                          </Index.Typography>
                          <Index.TextField
                            fullWidth
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Number"
                            size="small"
                            variant="filled"
                            className="form-input login-password profile-input-field"
                            name="mobileNumber"
                            data-testid="mobileNumber-input"
                            autoComplete="off"
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            value={values?.mobileNumber}
                            onChange={(e) => {
                              const value = e.target.value.trim();
                              if (/^\d*$/.test(value) && value.length <= 10) {
                                setFieldValue("mobileNumber", value);
                              }
                            }}
                            helperText={
                              touched.mobileNumber && errors.mobileNumber
                            }
                            error={Boolean(
                              errors.mobileNumber && touched.mobileNumber
                            )}
                          />
                        </Index.Box>
                      </Index.Grid>

                   
                      </Index.Grid>
                      <Index.Box className="edit-btn-box">
                          <Index.Button
                            type={loading ? "button" : "submit"}
                            data-testid="button"
                            variant="contained"
                            className="edit-btn"
                          >
                            {loading ? (
                              <PagesIndex.AdminButtonLoader />
                            ) : (
                              <>
                              <Index.SaveAsIcon fontSize="small"/>
                              Submit
                              </>
                            )}
                          </Index.Button>
                        </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Box>
    </Index.Box>
  );
};

export default EditProfile;
