import React from "react";
import DataService from "../config/DataService";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const PrivateRoutes = () => {
  const adminToken = localStorage.getItem("adminToken");
 const navigate = useNavigate();
 const isAuthenticate = (token) => {
  if (!token) return false;
  if (DataService?.defaults?.headers?.common) {
    DataService.defaults.headers.common.auth = token;
  }
  return true;
};

  DataService.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("adminToken");
        navigate("/admin"); 
      }
      return Promise.reject(error);
    }
  );

  return isAuthenticate(adminToken) ? <Outlet /> : <Navigate to="/admin" replace />;
};

export default PrivateRoutes;
