
import { configureStore,combineReducers  } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import adminReducer from './slice/admin/AdminSlice';

const persistConfig = {
  key: 'root',
  storage,
}
const rootReducer = combineReducers({
  admin: adminReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  reducer: {
    data: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
  },
})
export const persistor = persistStore(store)





