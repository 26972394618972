import React from 'react'
import Index from '../../Index'

export default function WhiteButton(props) {
  return (
    <>
       <Index.Box className='white-btn-main'>
        <Index.Button className={props.className} onClick={props.onClick} disableRipple>{props.btnLabel}</Index.Button>
      </Index.Box>


      {/* use this button like below demo */}
      {/* <Index.WhiteButton className="white-btn" btnLabel="View Button"/> */}</>
  )
}
