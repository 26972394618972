import { CircularProgress } from "@mui/material";
import React from "react";
import { green } from '@mui/material/colors';
import { ClipLoader } from "react-spinners";
// import HashLoader from "react-spinners/HashLoader";
// import PulseLoader from "react-spinners/PulseLoader";
const ButtonLoader = ({ color, loading, ariaLabel, dataTestId,size }) => {

    // const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();

  // const buttonSx = {
  //   ...(success && {
  //     bgcolor: green[500],
  //     '&:hover': {
  //       bgcolor: green[700],
  //     },
  //   }),
  // };

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
    return (
    <>
      {/* <PulseLoader
        color={color}
        loading={loading}
        size={size||15}
        aria-label={ariaLabel}
        data-testid={dataTestId}

      /> */}
       {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
    </>
  );
};

export default ButtonLoader;

export const AdminButtonLoader = () => {
  return (
    <ClipLoader size={20} color="#ffff"/> 
  );
}
