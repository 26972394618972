
// import onecardimgone from '../assets/images/jpg/one-card-imgone.png'

import dummyImg from "../assets/images/jpg/dummy-image.jpg"

const Jpg = {
    // onecardimgone,
    dummyImg
    
}

export default Jpg;