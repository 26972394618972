import axios from "axios";
import { PagesIndex } from "../container/PagesIndex";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const ImageURL = process.env.REACT_APP_IMAGE_URL;

const DataService = axios.create({
  baseURL: API_ENDPOINT,
});
// DataService.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers.auth = token;
//     }
//     return config;
//   },
//   (error) => {
//     // console.log(error, 32);
//     return Promise.reject(error);
//   }
// );

export default DataService;

// get call
export const doGet = async (url) => {
  try {
    const response = await DataService.get(url);
    if (response?.data?.status === 200) {
      return response;
    }
  } catch (error) {
    PagesIndex.toast.error(error?.response?.data?.message, { autoClose: 2000 });
  }
};

//Get Queary Call
export const doGetByQueary = async (url, queary, isShow = true) => {
  try {
    const response = await DataService.get(url, { ...queary });
    // if (response?.data?.status == 200) {
    //   return response;
    // }
    if (response?.data?.status === 201 || 200) {
      // PagesIndex.toast.success(response?.data?.message);
      return response;
    }
  } catch (error) {
    if (isShow) {
      PagesIndex.toast.error(error?.response?.data?.message, {
        autoClose: 2000,
      });
    }
  }
};

//Post Call
export const doPost = async (url, data, isShow = true) => {
  try {
   
    let id = '';
    if (await data?.wallet) {
      id = data?.id
    }
    const response = await DataService.post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
   
    if (response?.data?.status === 201 || 200) {
      if (id) {
        if (response?.data?.data?._id === id) {
          PagesIndex.toast.success(response?.data?.message, {
            autoClose: 2000,
          });
        }
      } else if (isShow) {
        PagesIndex.toast.success(response?.data?.message, { autoClose: 2000 });
      }

      return response;
    }
  } catch (error) {
    PagesIndex.toast.error(error?.response?.data?.message, { autoClose: 2000 });
  }
};

//Post FormData Call
export const doPostFormData = async (url, data) => {
  try {
    const response = await DataService.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.data.status === 201 || 200) {
      PagesIndex.toast.success(response.data.message, { autoClose: 2000 });
      return response;
    }
  } catch (error) {
    PagesIndex.toast.error(error.response.data.message, { autoClose: 2000 });
  }
};
export const doPostGetParams = async (url, data, id) => {
  try {
    delete data?.id;

    const response = await DataService.post(`${url}?id=${id}`, data);
    if (response?.data?.status === 200) {
      PagesIndex.toast.success(response.data.message, { autoClose: 2000 });
      return response;
    }
  } catch (error) {
    PagesIndex.toast.error(error?.response?.data?.message, { autoClose: 2000 });
  }
};

export const doGetParams = async (url, id) => {
 
  try {
    const response = await DataService.get(`${url}/${id}`);
    if (response?.data?.status === 200) {
      //   PagesIndex.toast.success();
      return response;
    }
  } catch (error) {
    PagesIndex.toast.error(error?.response?.data?.message, { autoClose: 2000 });
  }
};


// DataService.interceptors.request.use(
//   (config) => {
//     config.headers.auth = localStorage.getItem("token");
//     return config;
//   },
//   (error) => {
//     return error;
//   }
// );


