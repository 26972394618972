import React, { useState } from "react";
import Index from "../../Index";
import "./defaultLayout.css";
import { PagesIndex } from "../../../container/PagesIndex";

const Sidebar = ({ open, setOpen }) => {
  const [openSetting, setOpenSetting] = useState(false);

  let location = Index.useLocation();
  const navigate = Index.useNavigate();

  const handleClickAccount = () => {
    setOpenSetting(!openSetting);
  };

  return (
    <Index.Box className={`admin-main-sidebar ${open ? "open" : ""}`}>
      <Index.Box className="admin-sidebar-logo">
        {/* <Index.Png.adminlogo /> */}
        <img src={Index.Png.adminlogo} alt="Admin Logo" />
        <Index.Typography>Admin</Index.Typography>
        <Index.Button
          onClick={() => {
            setOpen(!open);
          }}
          className="sidebar-close-btn"
        ></Index.Button>
      </Index.Box>

      <Index.Box className="close-icon-phone">
        <PagesIndex.LightTooltip title={"Close"}>
      <Index.CloseIcon onClick={()=>{setOpen(false)}} />
        </PagesIndex.LightTooltip>
      </Index.Box>

      <Index.Box className="admin-sidebar-list cus-scrollbar">
        <Index.List className="admin-sidebar-list-inner">
          <Index.ListItem
            className={
              location.pathname === "/admin/user-list"
                ? "admin-sidebar-listitem-active"
                : "admin-sidebar-listitem"
            }
            
            onClick={() => navigate("/admin/user-list")}
          >
            <Index.Box>
              <Index.PersonIcon />
            </Index.Box>
            User Management
          </Index.ListItem>

          <Index.ListItem
            className={
              location.pathname === "/admin/activity"
                ? "admin-sidebar-listitem-active"
                : "admin-sidebar-listitem"
            }
            onClick={() => navigate("/admin/activity")}
          >
            <Index.Box>
              <Index.LocalActivityIcon />
            </Index.Box>
            Presale Activity
          </Index.ListItem>

          <Index.ListItem
            className={
              location.pathname === "/admin/history"
                ? "admin-sidebar-listitem-active"
                : "admin-sidebar-listitem"
            }
            onClick={() => navigate("/admin/history")}
          >
            <Index.Box>
              <Index.HistoryIcon />
            </Index.Box>
            Presale History
          </Index.ListItem>

          <Index.ListItem
            className="admin-sidebar-listitem"
            onClick={handleClickAccount}
          >
            <Index.Box>
              <Index.SettingsIcon />
            </Index.Box>
            Setting
            {openSetting ? (
              <Index.ExpandLess className="expandless-icon" />
            ) : (
              <Index.ExpandMore className="expandmore-icon" />
            )}
          </Index.ListItem>

          <Index.Collapse
            in={openSetting}
            timeout="auto"
            unmountOnExit
            className="submenu-collapse"
          >
            <Index.List
              component="div"
              disablePadding
              // className="admin-sidebar-submenulist"
            >
              <Index.ListItem
                sx={{ marginBottom: "10px" }}
                onClick={() => navigate("/admin/social")}
                className={
                  location.pathname === "/admin/social"
                    ? "admin-sidebar-listitem-active"
                    : "admin-sidebar-listitem"
                }
              >
                <Index.Box>
                  <Index.GroupsIcon />
                </Index.Box>
                Social Links
              </Index.ListItem>

              <Index.ListItem
                sx={{ marginBottom: "10px" }}
                onClick={() => navigate("/admin/top-section")}
                className={
                  location.pathname === "/admin/top-section"
                    ? "admin-sidebar-listitem-active"
                    : "admin-sidebar-listitem"
                }
              >
                <Index.Box>
                  <Index.SpaceDashboardIcon />
                </Index.Box>
                Top Section
              </Index.ListItem>

              <Index.ListItem
                sx={{ marginBottom: "10px" }}
                onClick={() => navigate("/admin/information")}
                className={
                  location.pathname === "/admin/information"
                    ? "admin-sidebar-listitem-active"
                    : "admin-sidebar-listitem"
                }
              >
                <Index.Box>
                  <Index.FeedIcon />
                </Index.Box>
                Information
              </Index.ListItem>

              <Index.ListItem
                sx={{ marginBottom: "10px" }}
                onClick={() => navigate("/admin/feature")}
                className={
                  location.pathname === "/admin/feature" ||
                  location.pathname === "/admin/feature/add-edit"
                    ? "admin-sidebar-listitem-active"
                    : "admin-sidebar-listitem"
                }
              >
                <Index.Box>
                  <Index.FeaturedPlayListIcon />
                </Index.Box>
                Feature List
              </Index.ListItem>

              <Index.ListItem
                sx={{ marginBottom: "10px" }}
                onClick={() => navigate("/admin/analytics")}
                className={
                  location.pathname === "/admin/analytics"
                    ? "admin-sidebar-listitem-active"
                    : "admin-sidebar-listitem"
                }
              >
                <Index.Box>
                  <Index.AnalyticsIcon />
                </Index.Box>
                Public Analytics
              </Index.ListItem>

              <Index.ListItem
                sx={{ marginBottom: "10px" }}
                onClick={() => navigate("/admin/roadmap")}
                className={
                  location.pathname === "/admin/roadmap" ||
                  location.pathname === "/admin/roadmap/add-edit"
                    ? "admin-sidebar-listitem-active"
                    : "admin-sidebar-listitem"
                }
              >
                <Index.Box>
                  <Index.TrackChangesIcon />
                </Index.Box>
                Roadmap List
              </Index.ListItem>

              <Index.ListItem
                sx={{ marginBottom: "10px" }}
                onClick={() => navigate("/admin/tokenomics")}
                className={
                  location.pathname === "/admin/tokenomics"
                    ? "admin-sidebar-listitem-active"
                    : "admin-sidebar-listitem"
                }
              >
                <Index.Box>
                  <Index.AccountBalanceWalletIcon />
                </Index.Box>
                Tokenomics
              </Index.ListItem>
            </Index.List>
          </Index.Collapse>
        </Index.List>
      </Index.Box>
    </Index.Box>
  );
};

export default Sidebar;
