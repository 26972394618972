import React, { useState } from "react";
import "./login.css";
import Index from "../../../Index";
import { PagesIndex } from "../../../PagesIndex";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate=PagesIndex.useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  
  let initialValues = {
    email: "",
    password: "",
    };

  const handleLoginFormSubmit =async (value) => {
    setLoading(true)
    try {
       const res = await PagesIndex.adminLogin(value)
       if(res?.data?.status === 200){
        localStorage.setItem("adminToken",res?.data?.data?.token);
        navigate("/admin/user-list");
       }
    } catch (error) {
    }
    setLoading(false);
  };
  

  return (
    <Index.Box className="admin-main">
      <Index.Box className="login-section">
        <Index.Box className="admin-main-bg">
          <Index.Box className="auth-main">
            {/* <Index.Box className="auth-left">
        <img src={PagesIndex.Png.logo} alt="logo" className="" />
      </Index.Box> */}
            <Index.Box className="auth-right">
              <Index.Box className="auth-common-box">
                <Index.Box className="auth-header">
                  <Index.Box className="logo-name">
                    {/* <img src={PagesIndex.Png.LogoName} alt="logo" className="" /> */}
                  </Index.Box>
                  <Index.Box className="auth-title">Admin Login</Index.Box>
                  <Index.Box className="auth-subtitle">
                    Please enter your credentials to Login
                  </Index.Box>
                </Index.Box>

                <PagesIndex.Formik
                  enableReinitialize
                  onSubmit={handleLoginFormSubmit}
                  initialValues={initialValues}
                    validationSchema={PagesIndex.AdminLoginSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Index.Stack
                      component="form"
                      spacing={2}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <Index.Box className="auth-body">
                        <Index.Grid container spacing={3}>
                          {/* Email */}
                          <Index.Grid item xs={12}>
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="form-lable">
                                Email
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Email"
                                variant="filled"
                                className="form-input login"
                                name="email"
                                inputProps={{
                                  maxLength:64
                                }}
                                data-testid="email-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={(e) => {
                                  setFieldValue(
                                    "email",
                                    e.target.value.toLowerCase().trim()
                                  );
                                }}
                                helperText={touched.email && errors.email}
                                error={Boolean(errors.email && touched.email)}
                              />
                            </Index.Box>
                          </Index.Grid>

                          {/* Password */}
                          <Index.Grid item xs={12}>
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="form-lable">
                                Password
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Password"
                                variant="filled"
                                className="form-input login-password"
                                name="password"
                                data-testid="password-input"
                                aria-label="password"
                                type={showPassword ? "text" : "password"}
                                autoComplete="off"
                                inputProps={{
                                  className: "input_props",
                                  maxLength:32
                                }}
                                InputLabelProps={{ className: "add-formlabel" }}
                                FormHelperTextProps={{
                                  className: "input_label_props",
                                }}
                                onBlur={handleBlur}
                                value={values.password}
                                onChange={handleChange}
                                helperText={touched.password && errors.password}
                                error={Boolean(
                                  errors.password && touched.password
                                )}
                                InputProps={{
                                  endAdornment: (
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                      className="password-icon"
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                      >
                                        {showPassword ? (
                                          <Index.Visibility />
                                        ) : (
                                          <Index.VisibilityOff />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  ),
                                }}
                              />
                            </Index.Box>
                          </Index.Grid>

                          {/* Forgot Password */}
                          <Index.Grid item xs={6}>
                            <Index.Box className="box-login-text forgot bluebox-text forgot_password">
                              <PagesIndex.Link
                                to="/admin/forgot-password"
                                className="auth-link forgot-password"
                              >
                                <Index.Typography variant="body1" component="p">
                                  Forgot password?
                                </Index.Typography>
                              </PagesIndex.Link>
                            </Index.Box>
                          </Index.Grid>

                          {/* Button */}
                          <Index.Grid item xs={12}>
                            <Index.Box className="auth-btn-box border-btn-main">
                              <Index.Button
                              type={loading ? "button" : "submit"}
                                data-testid="button"
                                variant="contained"
                                className="border-btn custom-btn-login"     
                              >
                                {loading?<PagesIndex.AdminButtonLoader/>:"Login"}
                              </Index.Button>
                              
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                      </Index.Box>
                    </Index.Stack>
                  )}
                </PagesIndex.Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default Login;
