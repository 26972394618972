import React, { useState } from 'react'
import Index from '../../../Index';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';
import "./profile.css";

const Profile = () => {
  const [activeTab, setActiveTab] = useState("EditProfile");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
    <Index.Box className="presale-wraper-prof">
      <Index.Typography>Account Setting</Index.Typography>
    <Index.Box className="history-custom-btn-group-prof">
      <Index.Box className="history-btn-prof" sx={{background : activeTab === "EditProfile"? "linear-gradient(99deg, #4d5e78 0%, #3557c7 100%)":"linear-gradient(99deg, #2b5af3 0%,  #38ff8e 100%" }} onClick={() => handleTabClick("EditProfile")}>
      Profile
      </Index.Box>

      <Index.Box className="history-btn-prof" sx={{background : activeTab === "ChangePassword"? "linear-gradient(99deg, #4d5e78 0%, #3557c7 100%)":"linear-gradient(99deg, #2b5af3 0%,  #38ff8e 100%" }} onClick={() => handleTabClick("ChangePassword")}>
       Change Password
      </Index.Box>
 
    </Index.Box>
  
  </Index.Box>
  <Index.Box>
    {!activeTab && <EditProfile />}
      {activeTab === "EditProfile" && <EditProfile />}
      {activeTab === "ChangePassword" && <ChangePassword/>}

      
    </Index.Box>
  </>
  )
}

export default Profile