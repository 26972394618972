import React, { useState, useRef, useEffect  } from "react";
import Index from "../../../Index";
import debounce from 'lodash.debounce';
import "./roadmap.css";
import { PagesIndex } from "../../../PagesIndex";
import moment from "moment";

const AddEditRoadMap = () => {
  const [loading, setLoading] = useState(false);
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const { data } = location.state || {};
  const descriptionRef = useRef(null);


  const initialValues = {
    date: data ? moment(data?.date).format('YYYY-MM-DD') : "",
    title: data ? data?.title : "",
    description: data ? data?.description : "",
  };


  const informationFormSubmit = async (values) => {
    setLoading(true);
    const roadmapValue={
        id: data ? data?._id : "",
        date :values?.date,
        title:values.title,
        description :values.description
    }
    try {
      const res = await PagesIndex.adminRoadMapAddUpdate(roadmapValue);
      if (res?.data?.status === 200 || 201) {
        navigate("/admin/roadmap");
      }
    } catch (error) {
      console.log(error, "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      console.log('Resized:', descriptionRef.current?.offsetHeight);
    }, 100);

    const observer = new ResizeObserver(handleResize);
    const currentRef = descriptionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <Index.Box className="presale-userlist">
        <Index.Box className="user-heading-box feature-heading">
          <Index.Box className="user-heading">
            {data?._id ? "Edit Roadmap" : "Add Roadmap"}
          </Index.Box>
          <Index.Button
            className="back-btn"
            onClick={() => navigate("/admin/roadmap")}
          >
            <Index.ArrowBackIcon fontSize="small"/>
            Back
          </Index.Button>
        </Index.Box>

        <Index.Box className="admin-edit-feature-main">
          <Index.Box className="admin-edit-profile-box section-box ">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={informationFormSubmit}
              initialValues={initialValues}
              validationSchema={PagesIndex.AdminRoadMapSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
              }) => (
                <form
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box>
                    <Index.Box className="edit-profile-content">
                      <Index.Box className="auth-body feature-form-box">
                        <Index.Grid container spacing={3}>

                          <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                               Title*
                              </Index.Typography>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="title"
                                size="small"
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="title"
                                data-testid="fullName-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                inputProps={{ maxLength: 128 }}
                                value={values.title}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (/^[a-zA-Z\s.,?]*$/.test(inputValue) ) {
                                    setFieldValue("title", inputValue);
                                  }
                                }}
                                helperText={touched.title && errors.title}
                                error={Boolean(errors.title && touched.title)}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                                Date*
                              </Index.Typography>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                type="date"
                                id="filled-hidden-label-normal"
                                size="small"
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="date"
                                data-testid="fullName-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                value={values.date}
                                onChange={handleChange}
                                helperText={touched.date && errors.date}
                                error={Boolean(errors.date && touched.date)}
                              />
                            </Index.Box>
                          </Index.Grid>

                        
                          <Index.Grid item xs={12}>
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                                Description*
                              </Index.Typography>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Description"
                                size="small"
                                multiline
                                rows={4}
                                maxRows={4}
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="description"
                                data-testid="mobileNumber-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                inputProps={{ maxLength: 512 }}
                                value={values.description}
                                onChange={handleChange}
                                ref={descriptionRef}
                                helperText={
                                  touched.description && errors.description
                                }
                                error={Boolean(
                                  errors.description && touched.description
                                )}
                              />
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                        <Index.Box className="edit-btn-box">
                          <Index.Button
                            type={loading ? "button" : "submit"}
                            data-testid="button"
                            variant="contained"
                            className="edit-btn"
                          >
                            {loading ? (
                              <PagesIndex.AdminButtonLoader />
                            ) : (
                              <>
                              <Index.SaveAsIcon fontSize="small"/>
                                Submit
                              </>
                            )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default AddEditRoadMap;

