import React, { useEffect, useState } from "react";
import "./dashboard.css";
import Index from "../../../Index"
import { Connection, PublicKey, clusterApiUrl, SystemProgram } from '@solana/web3.js';
import { Program, AnchorProvider, utils, web3 } from '@project-serum/anchor';
import { useWallet } from '@solana/wallet-adapter-react';
import idl from "../../../../idl/newdwspresale.json";

const Dashboard = () => {
  const wallet = useWallet();
  const connection = new Connection(clusterApiUrl('devnet'));

  const provider = new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions());
  const programID = new PublicKey(idl.metadata.address);
  const program = new Program(idl, programID, provider);
  const STATE_SEEDS = utils.bytes.utf8.encode('state');
  const [statePDA] = PublicKey.findProgramAddressSync([STATE_SEEDS], programID);

  const [rounds, setRounds] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const fetchRounds = async () => {
      try {
        const state = await program.account.state.fetch(statePDA);
        
        if (isMounted) {
          setRounds(state.rounds.map(round => ({
            active: round.active,
            price: round.price.toString(),
            balance: round.balance.toString(),
            tokens_sold: round.tokensSold?.toString(),
            start_time: round.startTime ? round.startTime.toNumber() : null,
            end_time: round.endTime?.toNumber(),
          })));

        }
      } catch (error) {
        console.error('Failed to fetch round data:', error);
      }
    };

    fetchRounds();

    return () => {
      isMounted = false;
    };
  }, []);

  const startRound = async (roundNumber) => {
    try {
      const tx = await program.methods.startRound(roundNumber).accounts({
        admin: wallet.publicKey,
        state: statePDA,
        systemProgram: SystemProgram.programId,
        rent: web3.SYSVAR_RENT_PUBKEY,
      }).rpc();

      console.log(`Round ${roundNumber + 1} started. Signature:`, tx);
      // Refresh rounds data
      const state = await program.account.state.fetch(statePDA);
      setRounds(state.rounds.map(round => ({
        active: round.active,
        price: round.price.toString(),
        balance: round.balance.toString(),
        tokens_sold: round.tokensSold?.toString(),
        start_time: round.startTime ? round.startTime.toNumber() : null,
        end_time: round.endTime?.toNumber(),
      })));
    } catch (error) {
      if (error.code === 6003) {
        alert(`Failed to start round ${roundNumber + 1}: Round is already active.`);
      } else {
        console.error(`Failed to start round ${roundNumber + 1}:`, error);
      }
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp * 1000);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  return (
    <Index.Box className="dashboard-main-div">
      <Index.Box className="dashboard-top-box">
      <Index.Box className="presale-heading-box">
      <Index.Typography>Presale Activity</Index.Typography>
       </Index.Box>
        <Index.Box className="dashboard-heading">
          <Index.TableContainer className="dash-table-container">
            <Index.Table className="dash-top-table">
              <Index.TableHead className="dash-top-table-head">
                <Index.TableRow>
                  <Index.TableCell >Offering Type</Index.TableCell>
                  <Index.TableCell align="center">Status</Index.TableCell>
                  <Index.TableCell align="center">Quantity</Index.TableCell>
                  <Index.TableCell align="center">Price</Index.TableCell>
                  <Index.TableCell align="center">Token Sold</Index.TableCell>
                  <Index.TableCell align="center">Raised</Index.TableCell>
                  <Index.TableCell align="center">Start Date</Index.TableCell>
                  <Index.TableCell align="center">End Date</Index.TableCell>
                  <Index.TableCell align="center">Action</Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody>
                {rounds.length>0?<>
                {rounds.map((round, index) => (
                  <Index.TableRow>
                    <Index.TableCell> Round {index+1}</Index.TableCell>
                    <Index.TableCell>
                      <Index.Box className="dash-body-cell-box">
                        <Index.Typography className="dash-top-input-textfield">
                          {round.active ? 'TRUE' : 'FALSE'} 
                        </Index.Typography>
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell>
                      <Index.Box className="dash-body-cell-box">
                        <Index.Typography className="dash-top-input-textfield">
                          {((round.balance)/10**9)}
                        </Index.Typography>
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell>
                      <Index.Box className="dash-body-cell-box">
                        <Index.Typography className="dash-top-input-textfield">
                          {round.price}
                        </Index.Typography>
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell>
                      <Index.Box className="dash-body-cell-box">
                        <Index.Typography className="dash-top-input-textfield">
                          {((round.tokens_sold)/10**9)}
                        </Index.Typography>
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell>
                      <Index.Box className="dash-body-cell-box">
                        <Index.Typography className="dash-top-input-textfield">
                        $ {((round.price * round.tokens_sold)/10**9)}
                        </Index.Typography>
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell>
                      <Index.Box className="dash-body-cell-box">
                        <Index.Typography className="dash-top-input-textfield">
                        {formatTimestamp(round.start_time)}
                        </Index.Typography>
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell>
                      <Index.Box className="dash-body-cell-box">
                        <Index.Typography className="dash-top-input-textfield">
                        {formatTimestamp(round.end_time)}
                        </Index.Typography>
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell>
                      <Index.Box className="dash-body-cell-button">
                        <Index.Button  onClick={() => startRound(index + 1)}>
                          Start Round {index + 1}
                        </Index.Button>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>
                ))}
                </> : 
                <Index.TableRow>
                 <Index.TableCell
                   colSpan={10}
                   align="center"
                   className="no-data-cell"
                 >
                   No data found....
                 </Index.TableCell>
               </Index.TableRow>}

              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
        {/* <Index.Box className="dash-middle-box-main">
          <Index.Box>
            <Index.Grid container spacing={2}>
              <Index.Grid item xs={6}>
                <Index.Box className="bg-change-recepient-box">
                  <Index.Box className="change-recepient-box-main">
                    <Index.Typography className="change-recepient-label">
                      Change Recepient Address
                    </Index.Typography>
                    <Index.TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      className="input-field"
                      placeholder="0x..."
                    />
                    <Index.Box className="dash-btn-box">
                      <Index.Button className="dash-btn">Change</Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={6}>
                <Index.Box className="bg-change-recepient-box">
                  <Index.Box className="change-recepient-box-main">
                    <Index.Typography className="change-recepient-label">
                      Change Private Sale Time
                    </Index.Typography>
                    <Index.TextField
                      size="small"
                      fullWidth
                      select
                      label="Select Phase"
                      className="input-field"
                    />
                    <Index.TextField
                      type="date"
                      fullWidth
                      size="small"
                      className="input-field-date input-field"
                    />
                    <Index.Box className="dash-btn-box">
                      <Index.Button className="dash-btn">Change</Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={6}>
                <Index.Box className="bg-change-recepient-box">
                  <Index.Box className="change-recepient-box-main">
                    <Index.Typography className="change-recepient-label">
                      Change Private Sale Price
                    </Index.Typography>
                    <Index.TextField
                      size="small"
                      fullWidth
                      select
                      label="Select Phase"
                      className="input-field"
                    />
                    <Index.TextField
                      fullWidth
                      size="small"
                      className="input-field-date input-field"
                      placeholder="Enter Amount"
                    />
                    <Index.Box className="dash-btn-box">
                      <Index.Button className="dash-btn">Change</Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={6}>
                <Index.Box className="bg-change-recepient-box">
                  <Index.Box className="change-recepient-box-main">
                    <Index.Typography className="change-recepient-label">
                      Recover Tokens
                    </Index.Typography>
                    <Index.TextField
                      size="small"
                      fullWidth
                      className="input-field"
                      placeholder="Enter Amount"
                    />
                    <Index.TextField
                      fullWidth
                      size="small"
                      className="input-field input-field-date"
                      placeholder="0x..."
                    />
                    <Index.Box className="dash-btn-box">
                      <Index.Button className="dash-btn">Recover</Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={6}>
                <Index.Box className="bg-change-recepient-box">
                  <Index.Box className="change-recepient-box-main">
                    <Index.Typography className="change-recepient-label">
                      Close PrivateSale
                    </Index.Typography>

                    <Index.Box className="dash-btn-box">
                      <Index.Button className="dash-btn-sale">
                        Change
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={6}>
                <Index.Box className="bg-change-recepient-box">
                  <Index.Box className="change-recepient-box-main">
                    <Index.Typography className="change-recepient-label">
                      Recover Private Sale Tokens
                    </Index.Typography>

                    <Index.Box className="dash-btn-box">
                      <Index.Button className="dash-btn-sale">
                        Change
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Box> */}
      </Index.Box>
    </Index.Box>
  );
};

export default Dashboard;
