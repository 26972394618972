import { createAsyncThunk } from "@reduxjs/toolkit";
import { PagesIndex } from "../../../container/PagesIndex";



export const adminLogin = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.ADMIN_LOGIN, data);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
      console.log(error);
    }
}

export const adminForgotPassword = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.ADMIN_FORGOT_PASSWORD, data);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}

export const adminResetPassword = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.ADMIN_RESET_PASSWORD, data);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}


export const adminChangePassword = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.CHANGE_PASSWORD, data);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}

export const adminProfileUpdate = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.UPDATE_PROFILE, data);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}

export const adminProfileDetail = createAsyncThunk('admin/fetchProfile', async () => {
    try {
        const response = await PagesIndex.doGet(PagesIndex.Api.ADMIN.GET_PROFILE);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
})


export const userListData =  async (page,rowsPerPage) => {
    try {
        const response = await PagesIndex.doGet(`${PagesIndex.Api.ADMIN.GET_USER_LIST}?page=${page}&limit=${rowsPerPage}`);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}


export const socialLinkData =  async () => {
    try {
        const response = await PagesIndex.doGet(PagesIndex.Api.COMMON.GET_SOCIAL_LINK);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}


export const adminSocialLinkUpdate = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.UPDATE_SOCIAL_DATA, data);
        if (response?.data?.status === 200 ||201) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}


export const topSectionData =  async () => {
    try {
        const response = await PagesIndex.doGet(PagesIndex.Api.COMMON.GET_TOP_SECTION);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}


export const adminTopSectionDataUpdate = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.UPDATE_TOP_SECTION, data);
        if (response?.data?.status === 200||201) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}

export const adminInformationDataUpdate = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.UPDATE_INFORMATION, data);
        if (response?.data?.status === 200||201) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}

export const informationData =  async () => {
    try {
        const response = await PagesIndex.doGet(PagesIndex.Api.COMMON.GET_INFORMATION_DATA);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}


export const featuresData =  async () => {
    try {
        const response = await PagesIndex.doGet(PagesIndex.Api.COMMON.GET_FEATURE);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}

export const adminFeatureRemove = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.REMOVE_FEATURE, data);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}

export const adminFeatureAddUpdate = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.ADD_UPDATE_FEATURE, data);
        if (response?.data?.status === 200||201) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}


export const adminAnaylitcsData =  async () => {
    try {
        const response = await PagesIndex.doGet(PagesIndex.Api.COMMON.GET_PUBLIC_ANALYTICS);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}

export const adminAnaylitcsUpdate = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.UPDATE_PUBLIC_ANALYTICS, data);
        if (response?.data?.status === 200||201) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}


export const adminRoadMapData =  async () => {
    try {
        const response = await PagesIndex.doGet(PagesIndex.Api.COMMON.GET_ROAD_MAP);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}

export const adminRoadMapRemove = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.REMOVE_ROAD_MAP, data);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}

export const adminRoadMapAddUpdate = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.ADD_UPDATE_ROAD_MAP, data);
        if (response?.data?.status === 200||201) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}


export const adminTokenomicsData =  async () => {
    try {
        const response = await PagesIndex.doGet(PagesIndex.Api.COMMON.GET_TOKEN0MICS);
        if (response?.data?.status === 200) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}

export const adminTokenomicsUpdate = async (data) => {
    try {
        const response = await PagesIndex.doPost(PagesIndex.Api.ADMIN.UPDATE_TOKEN0MICS, data);
        if (response?.data?.status === 200||201) {
            return response;
        }

    } catch (error) {
        console.log(error);

    }
}



