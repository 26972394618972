import React, { useEffect, useState } from 'react'
import Index from '../../../../Index'
import './analytics.css'
import { PagesIndex } from '../../../../../container/PagesIndex';

const Analytics = () => {
    const [anaylitcsData, setAnaylitcsData] = useState({});
    
    const getAnaylitcsData = async () => {
      try {
        const res = await PagesIndex.adminAnaylitcsData();
        if (res?.data?.status === 200) {
            setAnaylitcsData(res?.data?.data[0]);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };
  
    useEffect(() => {
      getAnaylitcsData();
    }, []);

    return (
        <>
            <Index.Box className='tokenomics-one-main'>
                <Index.Box className='container'>
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box display="grid" className="one-tokenomics-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                            <Index.Box className="recent-span-newsone" gridColumn={{ xs: "span 12", sm: "span 6", md: "span 5", lg: "span 5" }} >
                                <Index.Box className="grid-col">
                                    <Index.Box className='one-tokenomics-left icon-content-title-after'>
                                        <Index.motion.div
                                            animate={{ y: 100, opacity: 0, }}
                                            transition={{ duration: 0.8 }}
                                            whileInView={{ y: 0, opacity: 1, }}>
                                            <Index.Box className='one-tokenomics-title info-title-icon info-light-title-main'>
                                                <Index.Typography className='title-gradient icon-title-info info-titel-light' variant="h1">Public Analytics</Index.Typography>
                                            </Index.Box>
                                        </Index.motion.div>
                                        <Index.motion.div
                                            animate={{ y: 150, opacity: 0, }}
                                            transition={{ duration: 0.8 }}
                                            whileInView={{ y: 0, opacity: 1, }}>
                                            <Index.Typography className='one-tokenomics-para' variant="p" component="p">
                                                {anaylitcsData?.description}
                                                </Index.Typography>
                                        </Index.motion.div>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="recent-span-newsone" gridColumn={{ xs: "span 12", sm: "span 6", md: "span 7", lg: "span 7" }}>
                                <Index.Box className="grid-col">
                                    <Index.motion.div
                                        animate={{ y: 100, opacity: 0, }}
                                        transition={{ duration: 0.8 }}
                                        whileInView={{ y: 0, opacity: 1, }}>
                                        <Index.Box className="one-tokenomicsimg-img-sec">
                                            <img src={`${PagesIndex.ImageURL}${anaylitcsData?.image}`} className='one-tokenomicsimg-img' alt='tokenomicsImg' />
                                        </Index.Box>
                                    </Index.motion.div>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    )
}

export default Analytics