const Api = {
 
    Auth:{
        USER_REGISTER:"user/user-register"
    },
  
  ADMIN: {
    ADMIN_LOGIN: "admin/login",
    ADMIN_FORGOT_PASSWORD: "admin/forgot-password",
    ADMIN_RESET_PASSWORD: "admin/reset-password",
    GET_PROFILE: "admin/get-profile",
    UPDATE_PROFILE: "admin/edit-profile",
    CHANGE_PASSWORD: "admin/change-password",
    GET_USER_LIST: "admin/user-list",
    GET_SOCIAL_LINK :"common/get-social-link",
    UPDATE_SOCIAL_DATA:"admin/update-social-link",
    UPDATE_TOP_SECTION: "admin/update-top-section",
    UPDATE_INFORMATION: "admin/update-information",
    ADD_UPDATE_FEATURE :"admin/add-update-feature",
    REMOVE_FEATURE : "admin/delete-feature",
    UPDATE_PUBLIC_ANALYTICS: "admin/update-public-analytics",
    ADD_UPDATE_ROAD_MAP: "admin/add-update-roadmap",
    REMOVE_ROAD_MAP : "admin/delete-roadmap",
    UPDATE_TOKEN0MICS: "admin/add-tokeomics",

  },  
  COMMON:{
    GET_SOCIAL_LINK :"common/get-social-link",
    GET_TOP_SECTION :"common/get-top-section",
    GET_INFORMATION_DATA :"common/get-information",
    GET_FEATURE :"common/get-feature",
    GET_PUBLIC_ANALYTICS :"common/get-public-anaylitics",
    GET_ROAD_MAP :"common/get-road-map",
    GET_TOKEN0MICS :"common/get-tokenomics",
  }
};
export { Api };
