import React, { useEffect, useState } from "react";
import "./roadmap.css";
import Index from "../../../Index";
import { PagesIndex } from "../../../PagesIndex";

const Roadmap = () => {
  const navigate =PagesIndex.useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [open,setOpen]=useState(false);
  const [removeId,setRemoveId]=useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleDelete= async()=>{
    try {
      const res = await PagesIndex.adminRoadMapRemove({id:removeId});
      if (res?.data?.status === 200) {
        setOpen(false);
        setRemoveId('');
        getUserData();
      }
    } catch (error) {
    console.log(error,"error");
    }
  }

  const hanldeAddEdit= (data)=>{
    navigate("add-edit",{
      state: { data: data }
    });
  }

  const getUserData = async () => {
    setLoading(true);
    try {
      const res = await PagesIndex.adminRoadMapData();
      if (res?.data?.status === 200) {
        setData(res?.data?.data);
        setFilterData(res?.data?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserData();
  }, [page, rowsPerPage]);

  const handleSearchChange = (e) => {
    const search = e.target.value.trim();
    const res = data?.filter((item) => {
      const createdAtString = item.date
      ? PagesIndex.format(new Date(item?.date), "dd/MM/yyyy")
      : "";
      return (
        (item.title &&
          item.title?.toLowerCase()?.includes(search?.toLowerCase())) ||
          (item.description &&
            item.description?.toLowerCase()?.includes(search?.toLowerCase())) ||
            createdAtString.includes(search) 
      );
    });
    setFilterData(res);
    setPage(0);
  };

  return (
    <>
      <Index.Box className="presale-userlist">
        <Index.Box className="feature-heading-box">
          <Index.Box className="user-heading" >Roadmap List</Index.Box>
          <Index.Box className="search-btn-box">
          <Index.TextField
            size="small"
            placeholder="Search"
            className="user-search-input"
            onChange={handleSearchChange}
          />
          <Index.Button className="roadmap-add-btn" onClick={()=>hanldeAddEdit()}>
            <Index.AddCircleOutlineIcon fontSize="small"/>
            Add Roadmap</Index.Button>
          </Index.Box>
        </Index.Box>

        <Index.TableContainer className="feature-list-table-container">
          <Index.Table className="roadmap-list-top-table ">
            <Index.TableHead
              className="private-pre-table-head"
              stickyHeader
              aria-label="sticky table"
            >
              <Index.TableRow>
                <Index.TableCell align="center">S.No</Index.TableCell>
                <Index.TableCell>Title</Index.TableCell>
                <Index.TableCell>Description</Index.TableCell>
                <Index.TableCell>Date</Index.TableCell>
                <Index.TableCell>Action</Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            {!loading ? (
              <Index.TableBody>
               { filterData?.length > 0?
               <>
                {filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item, index) => (
                  <Index.TableRow key={index}>
                    <Index.TableCell align="center">
                      {page * rowsPerPage + index + 1}
                    </Index.TableCell>
                    
                    {item?.title?.length > 85 ? (
                    <PagesIndex.LightTooltip title={item?.title || ""} >
                    <Index.TableCell>{item?.title?.slice(0, 85) + ( "...")}</Index.TableCell>
                    </PagesIndex.LightTooltip>):(
                      <Index.TableCell>{item?.title}</Index.TableCell>
                    )}

                    {item?.description?.length > 185 ? (
                    <PagesIndex.LightTooltip title={item?.description || ""} >
                    <Index.TableCell>{item?.description?.slice(0, 185) + ( "...")}</Index.TableCell>
                    </PagesIndex.LightTooltip>):(
                  <Index.TableCell>{item?.description}</Index.TableCell>
                    )}
                    {/* <Index.TableCell>{item?.description}</Index.TableCell> */}
                  
                    <Index.TableCell>{PagesIndex.format(new Date(item?.date), "dd/MM/yyyy")}</Index.TableCell>
                    
                    <Index.TableCell
                      className="action-icon-box"
                    >
                      {/* <Index.VisibilityIcon className="vew-icon" /> */}
                      <PagesIndex.LightTooltip title={"Edit"}>
                      <Index.EditIcon fontSize="small"  className="edit-icon" 
                      onClick={()=>hanldeAddEdit(item)}/>
                      </PagesIndex.LightTooltip>
                      <PagesIndex.LightTooltip title={"Delete"}>
                      <Index.DeleteIcon fontSize="small"  className="delete-icon" 
                      onClick={()=>{setRemoveId(item?._id);setOpen(true)}} />
                   </PagesIndex.LightTooltip>
                    </Index.TableCell>
                  </Index.TableRow>
                ))}
                </>:
                 <Index.TableRow>
                 <Index.TableCell
                   colSpan={10}
                   align="center"
                   className="no-data-cell"
                 >
                   No roadmap found....
                 </Index.TableCell>
               </Index.TableRow>
                 }
              </Index.TableBody>
            ) : (
              ""
            )}
          </Index.Table>
        </Index.TableContainer>

        <Index.TablePagination
        className="table-list-pagination"
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={filterData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Index.Box>
      <PagesIndex.DeleteModal open={open} setOpen={setOpen} handleDelete={handleDelete}  />
    </>
  );
};

export default Roadmap;
