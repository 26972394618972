import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import "./tokenomics.css";
import { PagesIndex } from "../../../PagesIndex";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";

const Tokenomics = () => {
  const [loading, setLoading] = useState(false);
  const [socialData, setSocialData] = useState([]);

  const getTokenomicsData = async () => {
    try {
      const res = await PagesIndex.adminTokenomicsData();
      if (res?.data?.status === 200) {
        const formattedData =
          res?.data?.data[0]?.TokenoMics?.map((item) => ({
            amount: item.amount || "",
            percentage: item.percentage || "",
            text: item.text || "",
          })) || [];
        setSocialData(formattedData);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getTokenomicsData();
  }, []);

  const initialValues = {
    tokenomics: socialData.length
      ? socialData
      : [{ amount: "", percentage: "", text: "" }],
  };

  const tokenomicsSchema = Yup.object().shape({
    tokenomics: Yup.array().of(
      Yup.object().shape({
        text: Yup.string().required("Please enter title").noLeadingSpace("Title").noLeadingSpecialChar("Title")
        .noRepeatedSpecialChar("Title"),
        amount: Yup.string().required("Please enter amount "),
        percentage: Yup.string().required("Please enter percentage").noLeadingSpace("Percentage")
        .matches(/^(?:[a-zA-Z\s]+ )?(100(?:\.0{1,3})?%|[1-9]?\d(?:\.\d{1,4})?%)(?: [a-zA-Z\s]+)?$/,"Please enter valid percentage value"),
        // .matches(/^[a-zA-Z\s]*(100(?:\.0{1,3})?%|[1-9]?\d(?:\.\d{1,2})?%)[a-zA-Z\s]*$/,"Please enter valid percentage value"),
      })
    ),
  });

  const tokenomicsFormSubmit = async (values) => {
    setLoading(true);
    try {
      const res = await PagesIndex.adminTokenomicsUpdate({
        tokenomicsData: values?.tokenomics,
      });
      if (res?.data?.status === 200 || 201) {
        getTokenomicsData();
      }
    } catch (error) {
      console.log(error, "error");
    }
    setLoading(false);
  };

  return (
    <>
      <Index.Box className="presale-userlist">
        <Index.Box className="user-heading-box">
          <Index.Box className="user-heading">Tokenomics</Index.Box>
        </Index.Box>

        <Index.Box className="admin-edit-profile-main">
          <Index.Box className="admin-edit-profile-box social-main-box section-box">
            <Formik
              enableReinitialize
              onSubmit={tokenomicsFormSubmit}
              initialValues={initialValues}
              validationSchema={tokenomicsSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
              }) => (
                <form
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box className="edit-profile-content">
                    <Index.Box className="auth-body social-form-box">
                      <FieldArray name="tokenomics">
                        {({ push, remove }) => (
                          <>
                            {values.tokenomics.map((_, index) => (
                              <Index.Box className="token-list-main-box" key={index}>
                                <Index.Typography className="token-index-list">{index+1}.</Index.Typography>           
                              <Index.Grid container spacing={3} className="tokenomics-row">
                                
                              <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                                  <Index.Box className="form-group-prof">
                                    <Index.Typography className="form-lable-prof">
                                      Percentage*
                                    </Index.Typography>
                                    <Index.TextField
                                      fullWidth
                                      hiddenLabel
                                      placeholder="Percentage"
                                      size="small"
                                      variant="filled"
                                      className="form-input login-password profile-input-field"
                                      name={`tokenomics.${index}.percentage`}
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onFocus={handleFocus}
                                      inputProps={{ maxLength: 64 }}
                                      value={
                                        values.tokenomics[index].percentage
                                      }
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (/^[a-zA-Z0-9\s.%]*$/.test(inputValue) && (inputValue.match(/\./g) || []).length <= 1) {
                                          setFieldValue(`tokenomics.${index}.percentage`, inputValue);
                                        }
                                      }}
                                    // onChange={handleChange}
                                      helper
                                      helperText={
                                        touched.tokenomics?.[index]
                                          ?.percentage &&
                                        errors.tokenomics?.[index]?.percentage
                                      }
                                      error={Boolean(
                                        errors.tokenomics?.[index]
                                          ?.percentage &&
                                          touched.tokenomics?.[index]
                                            ?.percentage
                                      )}
                                    />
                                  </Index.Box>
                                </Index.Grid>

                                <Index.Grid item xs={12} sm={12} md={6} lg={6} >
                                  <Index.Box className="form-group-prof">
                                    <Index.Typography className="form-lable-prof">
                                      Amount*
                                    </Index.Typography>
                                    <Index.TextField
                                      fullWidth
                                      hiddenLabel
                                      placeholder="Amount"
                                      size="small"
                                      variant="filled"
                                      className="form-input login-password profile-input-field"
                                      name={`tokenomics.${index}.amount`}
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onFocus={handleFocus}
                                      inputProps={{ maxLength: 12 }}
                                      value={values.tokenomics[index].amount}
                                      type="string"
                                      onChange={(e) => {
                                        const inputValue = e.target.value.replace(/\D/g, "");
                                        if(inputValue !== "00"){
                                        setFieldValue(
                                          `tokenomics.${index}.amount`,
                                          inputValue?.slice(0,12)
                                        );
                                      }}
                                    }
                                      
                                      helperText={
                                        touched.tokenomics?.[index]?.amount &&
                                        errors.tokenomics?.[index]?.amount
                                      }
                                      error={Boolean(
                                        errors.tokenomics?.[index]?.amount &&
                                          touched.tokenomics?.[index]?.amount
                                      )}
                                    />
                                  </Index.Box>
                                </Index.Grid>

                                <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                                  <Index.Box className="form-group-prof">
                                    <Index.Typography className="form-lable-prof">
                                      Title*
                                    </Index.Typography>
                                    <Index.TextField
                                      fullWidth
                                      hiddenLabel
                                      placeholder="Title"
                                      size="small"
                                      variant="filled"
                                      className="form-input login-password profile-input-field"
                                      name={`tokenomics.${index}.text`}
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onFocus={handleFocus}
                                      inputProps={{ maxLength: 128 }}
                                      value={values.tokenomics[index].text}
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (/^[a-zA-Z\s.,?]*$/.test(inputValue)) {
                                          setFieldValue(`tokenomics.${index}.text`, inputValue);
                                        }
                                      }}
                                      helperText={
                                        touched.tokenomics?.[index]?.text &&
                                        errors.tokenomics?.[index]?.text
                                      }
                                      error={Boolean(
                                        errors.tokenomics?.[index]?.text &&
                                          touched.tokenomics?.[index]?.text
                                      )}
                                    />
                                  </Index.Box>
                                </Index.Grid>

                              
                                <Index.Grid
                                  item
                                  xs={6}
                                  sx={{ display: "flex" }}
                                  className="token-field-btn"
                                >
                                  {values.tokenomics.length > 1 && (
                                    <PagesIndex.LightTooltip title={"Remove"}>
                                    <Index.Box
                                      className="remove-btn"
                                      onClick={() => remove(index)}
                                    >
                                      {/* Remove */}
                                      <PagesIndex.RemoveCircleOutlineIcon/>
                                    </Index.Box>
                                    </PagesIndex.LightTooltip>
                                  )}
                                  {index === values.tokenomics.length - 1 &&
                                    values.tokenomics[index].text &&
                                    values.tokenomics[index].amount &&
                                    values.tokenomics[index].percentage && (
                                    <PagesIndex.LightTooltip title={"Add"}>

                                      <Index.Box
                                        className="add-btn"
                                        onClick={() =>
                                          push({
                                            amount: "",
                                            percentage: "",
                                            text: "",
                                          })
                                        }
                                        style={{ marginLeft: "1rem" }}
                                      >
                                        {/* Add Tokenomics */}
                                        <PagesIndex.AddCircleOutlineIcon/>
                                      </Index.Box>
                                      </PagesIndex.LightTooltip>
                                    )}
                                </Index.Grid>
                              </Index.Grid>
                              </Index.Box>
                            ))}
                          </>
                        )}
                      </FieldArray>

                      <Index.Box className="edit-btn-box">
                        <Index.Button
                          type={loading ? "button" : "submit"}
                          variant="contained"
                          className="edit-btn"
                        >
                          {loading ? (
                            <PagesIndex.AdminButtonLoader />
                          ) : (
                            <>
                            <Index.SaveAsIcon fontSize="small"/>
                            Submit
                            </>
                          )}
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Tokenomics;

