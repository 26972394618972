import {
    Box,
    Chip,
    FormHelperText,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    Button,
    IconButton,
    InputAdornment,
    OutlinedInput,
    List,
    ListItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextareaAutosize,
    Select,
    MenuItem,
    FormControl,
    RadioGroup,
    Radio,
    Tabs,
    Tab,
    Switch,
    styled,
    Modal,
    Menu,
    Collapse,
    Avatar,
    Stack,
    Pagination,
    SwipeableDrawer,
    Grid,
    InputBase,
    FormLabel,
    ListItemText,
    TablePagination
  } from "@mui/material";
  
//   import { Option } from '@mui/base/Option';
  import Visibility from "@mui/icons-material/Visibility";
  import VisibilityOff from "@mui/icons-material/VisibilityOff";
  import PreviewIcon from '@mui/icons-material/Preview';
  import ExpandLess from "@mui/icons-material/ExpandLess";
  import ExpandMore from "@mui/icons-material/ExpandMore";
  import MenuIcon from "@mui/icons-material/MoreVert";
  import { Link } from "react-router-dom";
  import EditIcon from "@mui/icons-material/Edit";
  import DeleteIcon from "@mui/icons-material/Delete";
  import SearchIcon from "@mui/icons-material/Search";
  import ReplyIcon from '@mui/icons-material/Reply';
  import OtpInput from "react-otp-input";
  import { toast } from "react-toastify";
  import { MuiOtpInput } from "mui-one-time-password-input";
  import { useLocation } from "react-router-dom";
  import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
  import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
  import Input from "@mui/material/Input";
  import LockIcon from '@mui/icons-material/Lock';
  import VisibilityIcon from '@mui/icons-material/Visibility';
  import CloseIcon from '@mui/icons-material/Close';
  import ContentCopyIcon from '@mui/icons-material/ContentCopy';
  import Jpg from "../assets/Jpg";
import Png from "../assets/Png";
import Svg from "../assets/Svg";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import BorderButton from "../component/common/Button/BorderButton";
  export default {
    Tooltip,
    Jpg,
    Png,
    Svg,
    LockIcon,
    VisibilityIcon,
    TablePagination,
    BorderButton,
    AddCircleIcon,
    AddCircleOutlineIcon,
    ArrowBackIcon,
    SaveAsIcon,
    ContentCopyIcon,
    tooltipClasses,
    CloseIcon,
    Input,
    FormLabel,
    ListItemText,
    OtpInput,
    toast,
    InputBase,
    Grid,
    Box,
    Chip,
    FormHelperText,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    Button,
    IconButton,
    InputAdornment,
    OutlinedInput,
    VisibilityOff,
    PreviewIcon,
    Visibility,
    Link,
    List,
    ListItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextareaAutosize,
    Select,
    MenuItem,
    FormControl,
    RadioGroup,
    Radio,
    Tabs,
    Tab,
    Switch,
    styled,
    Modal,
    Menu,
    ExpandLess,
    ExpandMore,
    Collapse,
    Stack,
    Pagination,
    SwipeableDrawer,
    MenuIcon,
    EditIcon,
    SearchIcon,
    DeleteIcon,
    MuiOtpInput,
    Avatar,
    ReplyIcon,
    KeyboardBackspaceIcon,
    // Option,

    useLocation,
  };
  