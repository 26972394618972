import React from 'react'
import "./defaultLayout.css"
import Index from '../../Index';
import Header from './header/Header';
import Footer from './footer/Footer';
import { Outlet } from 'react-router-dom';

const DefaultUserLayout = () => {
    return (
      <Index.Box >
     <Index.Box className="header-one-header-sec">
        <Header />
      </Index.Box>
        <Index.Box >
          <Outlet />
        </Index.Box>
        <Index.Box className="footer-one-main-sec">
        <Footer />
      </Index.Box>

      </Index.Box>
    );
  };


export default DefaultUserLayout