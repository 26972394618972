import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../container/user/pages/home/Home';
import Login from '../container/admin/auth/login/Login';
import ResetPassword from '../container/admin/auth/resetPassword/ResetPassword';
import ForgotPassword from '../container/admin/auth/forgotPassword/ForgotPassword';
import DefaultLayout from '../component/admin/defaultLayout/DefaultLayout';
import Dashboard from '../container/admin/pages/home/Dashboard';
// import History from '../container/admin/pages/history/History';
import PrivateRoutes from './PrivateRoutes';
import Profile from '../container/admin/pages/profile/Profile';
import UserList from '../container/admin/pages/user/UserList';
import PageNotFound from '../component/common/PageNotFound/PageNotFound';
import Social from '../container/admin/pages/social/Social';
import DefaultUserLayout from '../component/user/defaulLayout/DefaultLayout';
import Account from '../container/user/pages/account/Account';
import TopSection from '../container/admin/pages/topSection/TopSection';
import Information from '../container/admin/pages/information/Information';
import Feature from '../container/admin/pages/feature/Feature';
import AddEditFeature from '../container/admin/pages/feature/AddEditFeature';
import Analytics from '../container/admin/pages/analytics/Analytics';
import Roadmap from '../container/admin/pages/Roadmap/Roadmap';
import Tokenomics from '../container/admin/pages/tokenomics/Tokenomics';
import AddEditRoadMap from '../container/admin/pages/Roadmap/AddEditRoadMap';
import PrivateSale from '../container/admin/pages/history/PrivateSale';

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path="" element={<DefaultUserLayout/>} >
            <Route path="/" element={<Home />} />
            <Route path="/my-account" element={<Account />} />
            </Route>
        <Route path="admin" element={<Login />} />
        <Route path="admin/forgot-password" element={<ForgotPassword />} />
        <Route path="admin/reset-password" element={<ResetPassword />} />
        
        <Route element={<PrivateRoutes />}> 
        <Route path="admin" element={<DefaultLayout/>} >
          <Route path="user-list" element={<UserList/>} />
          <Route path="activity" element={<Dashboard/>} />
          {/* <Route path="history" element={<History/>} /> */}
          <Route path="history" element={<PrivateSale/>} />
          <Route path="profile" element={<Profile/>} />
          <Route path="social" element={<Social/>} />
          <Route path="top-section" element={<TopSection/>} />
          <Route path="information" element={<Information/>} />
          <Route path="feature" element={<Feature/>} />
          <Route path="feature/add-edit" element={<AddEditFeature/>} />
          <Route path="analytics" element={<Analytics/>} />
          <Route path="roadmap" element={<Roadmap/>} />
          <Route path="roadmap/add-edit" element={<AddEditRoadMap/>} />
          <Route path="tokenomics" element={<Tokenomics/>} />

          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Routers;
