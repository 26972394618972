import React, { useState } from "react";
import "./profile.css";
import Index from "../../../Index";
import { PagesIndex } from "../../../PagesIndex";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };


  const handleSubmit = async(values,{resetForm}) => {
    setLoading(true)
    try {
       await PagesIndex.adminChangePassword({password:values.newPassword});
       resetForm();
    } catch (error) {
      resetForm();
    }
    setLoading(false);
  };

  return (
    <Index.Box className="admin-edit-profile-main">
      <Index.Box className="admin-edit-profile-box">
        <Index.Box className="change-password-heading">
          <Index.Typography className="heading-text">
            Change Password
          </Index.Typography>
        </Index.Box>

        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={PagesIndex.AdminChangePasswordSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Index.Box>
                <Index.Box className="edit-profile-content">
                  <Index.Box className="auth-body">
                    <Index.Grid container spacing={3}>
                      <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                        <Index.Box className="form-group-prof">
                          <Index.Typography className="form-label-prof">
                            Old Password*
                          </Index.Typography>
                          <Index.TextField
                            fullWidth
                            hiddenLabel
                            id="oldPassword"
                            size="small"
                            placeholder="Old Password"
                            variant="filled"
                            className="form-input login-password change-password-input-field"
                            name="oldPassword"
                            type={showOldPassword ? "text" : "password"}
                            autoComplete="off"
                            inputProps={{
                              className: "input_props",
                              maxLength:32
                            }}
                            InputLabelProps={{ className: "add-formlabel" }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                            onBlur={handleBlur}
                            value={values.oldPassword}
                            onChange={handleChange}
                            helperText={touched.oldPassword && errors.oldPassword}
                            error={Boolean(errors.oldPassword && touched.oldPassword)}
                            InputProps={{
                              endAdornment: (
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    // className="password-icon"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowOldPassword}
                                    edge="end"
                                  >
                                    {showOldPassword ? (
                                      <Index.Visibility />
                                    ) : (
                                      <Index.VisibilityOff />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              ),
                            }}
                          />
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                        <Index.Box className="form-group-prof">
                          <Index.Typography className="form-label-prof">
                            New Password*
                          </Index.Typography>
                          <Index.TextField
                            fullWidth
                            hiddenLabel
                            id="newPassword"
                            placeholder="New Password"
                            variant="filled"
                            className="form-input login-password change-password-input-field"
                            name="newPassword"
                            size="small"
                            type={showNewPassword ? "text" : "password"}
                            autoComplete="off"
                            inputProps={{
                              className: "input_props",
                              maxLength:33
                            }}
                            InputLabelProps={{ className: "add-formlabel" }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                            onBlur={handleBlur}
                            value={values.newPassword}
                            onChange={handleChange}
                            helperText={touched.newPassword && errors.newPassword}
                            error={Boolean(errors.newPassword && touched.newPassword)}
                            InputProps={{
                              endAdornment: (
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    // className="password-icon"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    edge="end"
                                  >
                                    {showNewPassword ? (
                                      <Index.Visibility />
                                    ) : (
                                      <Index.VisibilityOff />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              ),
                            }}
                          />
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                        <Index.Box className="form-group-prof">
                          <Index.Typography className="form-label-prof">
                            Confirm Password*
                          </Index.Typography>
                          <Index.TextField
                            fullWidth
                            hiddenLabel
                            id="confirmPassword"
                            size="small"
                            placeholder="Confirm Password"
                            variant="filled"
                            className="form-input login-password change-password-input-field"
                            name="confirmPassword"
                            type={showConfirmPassword ? "text" : "password"}
                            autoComplete="off"
                            inputProps={{
                              className: "input_props",
                              maxLength:32
                            }}
                            InputLabelProps={{ className: "add-formlabel" }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            helperText={touched.confirmPassword && errors.confirmPassword}
                            error={Boolean(errors.confirmPassword && touched.confirmPassword)}
                            InputProps={{
                              endAdornment: (
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    // className="password-icon"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <Index.Visibility />
                                    ) : (
                                      <Index.VisibilityOff />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              ),
                            }}
                          />
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12}>
                        <Index.Box className="edit-btn-box">
                          <Index.Button
                            type={loading ? "button" : "submit"}
                            variant="contained"
                            className="edit-btn"
                          >
                            {loading ? (
                              <PagesIndex.AdminButtonLoader />
                            ) : (
                              <>
                              <Index.SaveAsIcon fontSize="small"/>
                              Submit
                              </>
                            )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Box>
    </Index.Box>
  );
};

export default ChangePassword;
