import React, { useEffect, useState, useRef } from "react";
import Index from "../../../Index";
import "./topSection.css";
import debounce from "lodash.debounce";
import { PagesIndex } from "../../../PagesIndex";

const TopSection = () => {
  const [loading, setLoading] = useState(false);
  const [socialData, setSocialData] = useState({});
  const descriptionRef = useRef(null);

  const getTopSectionData = async () => {
    try {
      const res = await PagesIndex.topSectionData();
      if (res?.data?.status === 200) {
        setSocialData(res?.data?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getTopSectionData();
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      console.log("Resized:", descriptionRef.current?.offsetHeight);
    }, 100);

    const observer = new ResizeObserver(handleResize);
    const currentRef = descriptionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, []);


  const initialValues = {
    header: socialData ? socialData?.header : "",
    description: socialData ? socialData?.description : "",
    pdf: socialData?.pdf ? socialData?.pdf : "",

  };

  const topSectionFormSubmit = async (values) => {
    const formData = new FormData();
    formData.append("header", values.header);
    formData.append("description", values.description);
    formData.append("pdf", values?.pdf);
    setLoading(true);
    try {
      const res = await PagesIndex.adminTopSectionDataUpdate(formData);
      if (res?.data?.status === 200 || 201) {
        getTopSectionData();
      }
    } catch (error) {
      console.log(error, "error");
    }
    setLoading(false);
  };

  return (
    <>
      <Index.Box className="presale-userlist">
        <Index.Box className="user-heading-box">
          <Index.Box className="user-heading">Top Section</Index.Box>
        </Index.Box>

        <Index.Box className="admin-edit-profile-main">
          <Index.Box className="admin-edit-profile-box social-main-box section-box">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={topSectionFormSubmit}
              initialValues={initialValues}
              validationSchema={PagesIndex.AdminTopSectionSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
              }) => (
                <form
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box>
                    <Index.Box className="edit-profile-content">
                      <Index.Box className="auth-body social-form-box">
                        <Index.Grid container spacing={3}>
                          <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                                Title*
                              </Index.Typography>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Title"
                                size="small"
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="header"
                                data-testid="fullName-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                inputProps={{ maxLength: 128 }}
                                value={values.header}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (/^[a-zA-Z\s.,?]*$/.test(inputValue)) {
                                    setFieldValue("header", inputValue);
                                  }
                                }}
                                helperText={touched.header && errors.header}
                                error={Boolean(errors.header && touched.header)}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                                White Paper*
                              </Index.Typography>
                              
                            <Index.Box className="white-paper-input-box">
                              <Index.TextField
                                // fullWidth
                                type="file"
                                hiddenLabel
                                title=""
                                id="filled-hidden-label-normal"
                                placeholder="White paper "
                                size="small"
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="pdf"
                                data-testid="email-input"
                                inputProps={{ accept: "application/pdf" }}
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                onChange={(e) => {
                                  const file = e.currentTarget.files[0];
                                  const allowedFormats = ["application/pdf"];
                                  if (
                                    file &&
                                    !allowedFormats.includes(file.type)
                                  ) {
                                    e.currentTarget.value = "";
                                    return ;
                                  }
                                  setFieldValue("pdf", file);
                                }}

                              />
                              <Index.Typography className="pdf-name-view" >{values?.pdf?.name ? values?.pdf?.name : socialData?.pdf  }</Index.Typography>
                              </Index.Box>
                              {!values?.pdf && !socialData?.pdf && <Index.Typography className="error-message">{errors?.pdf}</Index.Typography> }
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                                Description*
                              </Index.Typography>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Description"
                                size="small"
                                multiline
                                rows={4}
                                maxRows={4}
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="description"
                                data-testid="mobileNumber-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                value={values.description}
                                inputProps={{ maxLength: 512 }}
                                onChange={handleChange}
                                ref={descriptionRef}
                                helperText={
                                  touched.description && errors.description
                                }
                                error={Boolean(
                                  errors.description && touched.description
                                )}
                              />
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                        <Index.Box className="edit-btn-box">
                          <Index.Button
                            type={loading ? "button" : "submit"}
                            data-testid="button"
                            variant="contained"
                            className="edit-btn"
                          >
                            {loading ? (
                              <PagesIndex.AdminButtonLoader />
                            ) : (
                              <>
                                <Index.SaveAsIcon fontSize="small" />
                                Submit
                              </>
                            )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default TopSection;
