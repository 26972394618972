import React, { useEffect, useState } from 'react'
import Index from '../../../../Index'
import './tokenomics.css'
import { motion } from "framer-motion";
import { PagesIndex } from '../../../../../container/PagesIndex';
const TokenomicsTwo = () => {
    const [tokenomicsData, setTokenomicsData] = useState([]);
    const [totalSupply, setTotalSupply] = useState(0);
    const gettokenomicsData = async () => {
      try {
        const res = await PagesIndex.adminTokenomicsData();
        if (res?.data?.status === 200) {
          setTokenomicsData(res?.data?.data[0]?.TokenoMics);
          const total = res?.data?.data[0]?.TokenoMics?.reduce((sum, item) => sum + (parseFloat(item?.amount) || 0), 0);
          setTotalSupply(total);
        }
      } catch (error) {
        console.log(error, 'error');
      }
    };
  
    useEffect(() => {
      gettokenomicsData();
    }, []);

    return (
        <>
            <Index.Box className='tokenomics-two-main'>
                <Index.Box className='container'>
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box display="grid" className="two-tokenomics-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                            <Index.Box className="recent-span-newsone" gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} >
                                <Index.Box className="grid-col">
                                    <Index.Box className='two-tokenomics-left icon-content-title-after'>
                                        <motion.div
                                            animate={{ y: 100, opacity: 0, }}
                                            transition={{ duration: 0.8 }}
                                            whileInView={{ y: 0, opacity: 1, }}>
                                            <Index.Box className='one-tokenomics-title info-title-icon info-light-title-main'>
                                                <Index.Typography className='title-gradient icon-title-info info-titel-light' variant="h1">tokenomics</Index.Typography>
                                            </Index.Box>
                                        </motion.div>
                                        <Index.Box className="two-tokenomics-items">
                                            <Index.List className="two-tokenomics-main-ul" >
                                            {tokenomicsData&&tokenomicsData?.map((item,index)=>(

                                                <motion.ListItem className="two-tokenomics-main-li" animate={{ y: 100, opacity: 0, }}
                                                    transition={{ duration: 0.8 }}
                                                    whileInView={{ y: 0, opacity: 1, }}>
                                                    <Index.Box className="two-tokenomics-main-box">
                                                        <Index.Box className="two-tokenomics-ammount">
                                                            <Index.Typography className='list-title' variant="p" component="p"><span className='list-percent'>{item?.percentage}</span></Index.Typography>
                                                            <Index.Typography className='list-amount-text' variant="p" component="p">Amount</Index.Typography>
                                                        </Index.Box>
                                                        <Index.Box className="two-tokenomics-ammount">
                                                            <Index.Typography className='list-content' variant="p" component="p">{item?.text}</Index.Typography>
                                                            <Index.Typography className='list-content' variant="p" component="p">${item?.amount}</Index.Typography>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </motion.ListItem>
                                      ))}

                                            </Index.List>
                                        </Index.Box>

                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="recent-span-newsone" gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }}>
                                <Index.Box className="grid-col">
                                    <motion.div
                                        animate={{ y: 100, opacity: 0, }}
                                        transition={{ duration: 0.8 }}
                                        whileInView={{ y: 0, opacity: 1, }}>
                                        <Index.Box className="two-tokenomicsimg-img-sec">
                                            <img src={Index.Png.Tokenomics} className='two-tokenomicsimg-img' alt='twoTokenomics-img' />
                                            <Index.Typography className='two-tokenomics-img-text' variant="p" component="p"><span className='supply-text'>Total Supply</span><br />{totalSupply}</Index.Typography>
                                        </Index.Box>
                                    </motion.div>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box >
        </>
    )
}

export default TokenomicsTwo