import { Link, useLocation, useNavigate } from "react-router-dom"
import Jpg from "../assets/Jpg"
import { AdminForgotPasswordSchema, AdminLoginSchema,AdminSocialLinksSchema,AdminTopSectionSchema, AdminResetPasswordSchema,AdminProfileSchema, AdminChangePasswordSchema, AdminInformationSchema, AdminFeatureSchema, AdminAnalyticsSchema, AdminRoadMapSchema, AdminTokenomicsSchema } from "../validation/validation"
import { toast } from "react-toastify"
import { Form, Formik } from "formik";
import BorderButton from "../component/common/Button/BorderButton";
import DataService, { ImageURL, doGet, doGetByQueary, doGetParams, doPost, doPostFormData, doPostGetParams } from "../config/DataService";
import { Api } from "../config/Api";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../component/common/Button/ButtonLoader";
import Svg from "../assets/Svg";
import Png from "../assets/Png";
import { AdminButtonLoader } from "../component/common/Button/ButtonLoader";
import { adminForgotPassword,adminLogin,adminResetPassword,adminChangePassword, 
     adminProfileDetail,adminProfileUpdate,socialLinkData,adminSocialLinkUpdate,userListData, 
     adminTopSectionDataUpdate,
     topSectionData,
     informationData,
     adminInformationDataUpdate,
     featuresData,
     adminFeatureRemove,
     adminFeatureAddUpdate,
     adminAnaylitcsData,
     adminAnaylitcsUpdate,
     adminRoadMapAddUpdate,
     adminRoadMapRemove,
     adminRoadMapData,
     adminTokenomicsData,
     adminTokenomicsUpdate} from "../redux/slice/admin/AdminService";
import CopyToClipboard from "react-copy-to-clipboard";
import { format } from "date-fns";
import SocialLoginButton from "../component/common/Button/SocialLoginButton";
import {userWalletLoginAndRegister} from "../redux/slice/user/UserService"

import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { Program, AnchorProvider, web3 } from "@project-serum/anchor";
import DeleteModal from "../component/common/DeleteModal/DeleteModal";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { LightTooltip } from "../component/common/Element/Tooltip";
const PagesIndex={
 DeleteModal,
 AddCircleOutlineIcon,
 RemoveCircleOutlineIcon,
 useWallet,
 LightTooltip,
 WalletMultiButton,
 useConnection,
 PublicKey,
 Program,
 AnchorProvider,
 web3,

 Link,
 useLocation,
 SocialLoginButton,
 userWalletLoginAndRegister,
ImageURL,
CopyToClipboard,
format,
socialLinkData,
userListData,
informationData,
adminAnaylitcsData,
adminRoadMapData,
adminTokenomicsData,
adminTokenomicsUpdate,
adminRoadMapRemove,
adminRoadMapAddUpdate,
adminFeatureRemove,
featuresData,
adminInformationDataUpdate,
adminSocialLinkUpdate,
adminAnaylitcsUpdate,
adminFeatureAddUpdate,
adminForgotPassword,
adminResetPassword,
topSectionData,
adminTopSectionDataUpdate,
adminLogin,
adminProfileUpdate,
adminProfileDetail,
AdminTopSectionSchema,
AdminRoadMapSchema,
AdminTokenomicsSchema,
AdminInformationSchema,
AdminAnalyticsSchema,
AdminFeatureSchema,
adminChangePassword,
AdminButtonLoader,
AdminLoginSchema,
AdminForgotPasswordSchema,
AdminResetPasswordSchema,
AdminProfileSchema,
AdminChangePasswordSchema,
AdminSocialLinksSchema,

BorderButton,
toast,

// api call
DataService,
doGet,
doPost,
doGetByQueary,
doGetParams,
doPostFormData,
doPostGetParams,
Api,

useDispatch,
useNavigate,
useSelector,
Formik, Form,
ButtonLoader,
Svg,
Png,
Jpg


    
}

export {PagesIndex}