import { createSlice } from '@reduxjs/toolkit';
import { adminProfileDetail } from './AdminService';

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        profile: {},
        status: 'idle',
        error: null
    },
    reducers: {
        admiinLogin(state, action) {
            state.profile = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(adminProfileDetail.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(adminProfileDetail?.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.profile = action?.payload?.data?.data;
        })
        .addCase(adminProfileDetail.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    },
});

export const {  admiinLogin } = adminSlice.actions;

export default adminSlice.reducer;
