import React from 'react'
import Index from '../../Index'

export default function GradientButton(props) {
  return (
    <>
      <Index.Box className='gradient-btn-main'>
        <Index.Button className={props.className} onClick={props.onClick} disableRipple>{props.btnLabel}</Index.Button>
      </Index.Box>


      {/* use this button like below demo */}
      {/* <Index.GradientButton className="gredient-btn" btnLabel="View Button"/> */}
    </>
  )
}
