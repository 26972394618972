// component index

import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormHelperText,
  Modal,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Menu,
  MenuItem,
  Avatar,
  Grid,
  Tooltip,
  Badge,
  Select,
  Collapse
} from "@mui/material";

import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Jpg from "../assets/Jpg";
import Video from "../assets/Video";
import OwlCarousel from "react-owl-carousel3";
import { Link, useNavigate } from "react-router-dom";

import GradientButton from "./common/Button/GradientButton";
import WhiteButton from "./common/Button/WhiteButton";
import BorderButton from "./common/Button/BorderButton";
import ReadMoreButton from "./common/Button/ReadMoreButton";
import Header from "./user/defaulLayout/header/Header";
import { useLocation } from "react-router-dom";
import Analytics from "./user/pages/home/analytics/Analytics";
import Tokenomics from "./user/pages/home/tokenomics/tokenomics";
import HeroSection from "./user/pages/home/herosection/HeroSection";
import Features from "./user/pages/home/features/Features";
import Information from "./user/pages/home/information/Information";
import Roadmap from "./user/pages/home/roadmap/Roadmap";
import Footer from "./user/defaulLayout/footer/Footer";
import { motion } from "framer-motion";
import { ExpandLess, ExpandMore, Visibility, VisibilityOff } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from '@mui/icons-material/Person';
import HistoryIcon from '@mui/icons-material/History';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsIcon from '@mui/icons-material/Settings';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import FeedIcon from '@mui/icons-material/Feed';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CloseIcon from '@mui/icons-material/Close';
export default {
  Box,
  Grid,
  CloseIcon,
  ExpandLess,
  ExpandMore,
  Badge,
  Collapse,
  SpaceDashboardIcon,
  FeedIcon,
  FeaturedPlayListIcon,
  Select,
  AccountCircleIcon,
  PersonIcon,
  HistoryIcon,
  LocalActivityIcon,
  AnalyticsIcon,
  GroupsIcon,
  TrackChangesIcon,
  AccountBalanceWalletIcon,
  SettingsIcon,
  Button,
  Typography,
  Svg,
  Png,
  Jpg,
  Video,
  GradientButton,
  WhiteButton,
  BorderButton,
  ReadMoreButton,
  OwlCarousel,
  TextField,
  List,
  ListItem,
  Link,
  Header,
  Information,
  Tokenomics,
  HeroSection,
  Analytics,
  Roadmap,
  Features,
  Footer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  motion,
  FormHelperText,
  Modal,
  InputAdornment,
  IconButton,
  VisibilityOff,
  Visibility,
  OutlinedInput,
  Menu,
  MenuItem,
  Avatar,
  Tooltip,

  useNavigate,
  useLocation,
};
