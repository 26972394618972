import React, { useEffect, useState } from "react";
import Index from "../../Index";
import { PagesIndex } from "../../../container/PagesIndex";

const Header = (props) => {
  const { publicKey, connected, disconnect } = PagesIndex.useWallet();
  const adminProfileData = PagesIndex.useSelector(
    (state) => state?.data?.admin?.profile
  );
  const dispatch = PagesIndex.useDispatch();
  const location = PagesIndex.useLocation();
  const navigate = Index.useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    dispatch(PagesIndex.adminProfileDetail());
  }, [dispatch]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    navigate("/admin");
  };

  const formatPublicKey = (publicKey) => {
    const keyString = publicKey.toBase58();
    return `${keyString.slice(0, 4)}...${keyString.slice(-4)}`;
  };

  useEffect(() => {
    if (connected && publicKey) {
      const adminAddress = process.env.REACT_APP_ADMIN_PUBLIC_KEY;

      if (publicKey.toBase58() !== adminAddress) {
        disconnect();
        PagesIndex.toast.error("Admin address not found");
      }
    }
  }, [connected, publicKey]);

  return (
    <>
      <Index.Box className="admin-header">
        <Index.Box className="admin-header-left">
          <PagesIndex.LightTooltip
            title={props?.open == true ? "Close" : "Open"}
          >
            <Index.Button
              className="btn nav-menu-btn"
              onClick={() => {
                props.setOpen(!props.open);
              }}
            >
              <Index.Svg.HumburgerIcon />
            </Index.Button>
          </PagesIndex.LightTooltip>
          {/* <Index.Svg.CompanyLogo /> */}
        </Index.Box>

        
        <Index.Box className="admin-header-right custom-header-right">
          <PagesIndex.WalletMultiButton>
          <Index.Box className="main-connect">
                  <img className="connect" src={PagesIndex.Png.phantom}/>
                </Index.Box>
              {connected && publicKey ? formatPublicKey(publicKey) : "Connect"}
          </PagesIndex.WalletMultiButton>

          <Index.Box className="profile-drop-main">
            <Index.Box className="header-user-detail">
              <Index.Button
                className="btn profile-drop-btn"
                onClick={handleClick}
              >
                <Index.Box className="user-profile-btn"></Index.Box>
                <Index.Box className="user-profile-btn">
                  <img
                    src={
                      adminProfileData?.profile_image !== ""
                        ? `${PagesIndex.ImageURL}${adminProfileData?.profile_image}`
                        : Index.Jpg.dummyImg
                    }
                    className="header-profile"
                    alt={
                      adminProfileData?.profile_image !== ""
                        ? "Profile image"
                        : "Dummy image"
                    }
                  />
                </Index.Box>
                <PagesIndex.LightTooltip title={adminProfileData?.name?.length > 10 ?adminProfileData?.name:""}>
                <Index.Typography variant="p" className="head-user-title">
                  {adminProfileData?.name}
                </Index.Typography>
                </PagesIndex.LightTooltip>
              </Index.Button>
            </Index.Box>

            <Index.Menu
              className="btn profile-drop-menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Index.Link to={"/admin/profile"}>
                <Index.MenuItem
                  onClick={() => {
                    handleClose();
                  }}
                  className={
                    location.pathname === "/admin/profile"
                      ? "profile-drop-menuitem-active"
                      : "profile-drop-menuitem"
                  }
                >
                  Profile
                </Index.MenuItem>
              </Index.Link>

              {/* <Index.Link
              to={"/admin/change-password"}
            >
            
              <Index.MenuItem
                onClick={() => {
                  handleClose();
                }}
                className="profile-drop-menuitem"
              >
                Change Password
              </Index.MenuItem>
            </Index.Link> */}

              <Index.MenuItem
                onClick={() => {
                  handleLogout();
                }}
                className="profile-drop-menuitem"
              >
                Logout
              </Index.MenuItem>
            </Index.Menu>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Header;
