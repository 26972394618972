import React, { useState, useEffect, useMemo } from "react";
import Index from "../../../../Index";
import "./herosection.css";
import { motion } from "framer-motion";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Program, AnchorProvider, utils, web3, BN } from '@project-serum/anchor';
import * as anchor from "@project-serum/anchor";
import { Connection, PublicKey, clusterApiUrl, SystemProgram } from '@solana/web3.js';
import { useWallet } from "@solana/wallet-adapter-react";
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddressSync, ASSOCIATED_TOKEN_PROGRAM_ID, createAssociatedTokenAccountInstruction } from '@solana/spl-token';
import { Buffer } from 'buffer';
import idl from "../../../../../idl/newdwspresale.json";
import { PagesIndex } from "../../../../../container/PagesIndex";

window.Buffer = Buffer;

export default function HeroSection() {
  const targetDate = new Date(process.env.REACT_APP_HERO_SECTION_TARGET_DATE);
  let token = localStorage.getItem("userToken");
  const [timeLeft, setTimeLeft] = useState('');
  let round = "round2"
  const [socialData, setSocialData] = useState({});
  const [totalTokensSold, setTotalTokensSold] = useState(0);
  const [totalRaised, setTotalRaised] = useState(0);
  const [roundData, setRoundData] = useState({ round: '', price: '' })
  const [round1Data, setRound1Data] = useState({ balance: '', price: '', raise: '' });
  const [round2Data, setRound2Data] = useState({ balance: '', price: '', raise: '' });
  const [round3Data, setRound3Data] = useState({ balance: '', price: '', raise: '' });

  const wallet = useWallet();
  const [amount, setAmount] = useState('');
  const [tokenValue, setTokenValue] = useState('');
  const [amountError, setAmountError] = useState('')
  const [loading, setLoading] = useState(false);
  const connection = useMemo(() => new Connection(clusterApiUrl('devnet')), []);

  const provider = useMemo(() => {
    if (!wallet?.connected) return null;
    return new AnchorProvider(
      connection,
      wallet,
      AnchorProvider.defaultOptions()
    );
  }, [wallet, connection]);

  const getTopSectionData = async () => {
    try {
      const res = await PagesIndex.topSectionData();
      if (res?.data?.status === 200) {
        setSocialData(res?.data?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getTopSectionData();
  }, []);


  const getProvider = () => {
    return new anchor.AnchorProvider(connection, wallet, {
      preflightCommitment: "processed",
    });
  };

  const programID = new PublicKey(idl.metadata.address);
  const tokenMint = new PublicKey(process.env.REACT_APP_TOKEN_MINT);
  const paymentMint = new PublicKey(process.env.REACT_APP_PAYMENT_MINT);
  const STATE_SEEDS = utils.bytes.utf8.encode('state');
  const VEST_SEEDS = utils.bytes.utf8.encode('vesting');
  const ADMIN_PUBLIC_KEY = new PublicKey(process.env.REACT_APP_ADMIN_PUBLIC_KEY);

  // You can only buy using payment mint tokens 
  const calculateRaise = (balance, price) => (balance * price);

  const fetchData = async () => {
    const provider = await getProvider();

    const program = new anchor.Program(idl, programID, provider);
    const [stateAccountPubkey] = await PublicKey.findProgramAddress(
      [Buffer.from("state")],
      programID
    );
    try {
      const stateAccount = await program.account.state.fetch(stateAccountPubkey);
      const rounds = stateAccount.rounds.map(round => ({
        active: round.active,
        price: parseFloat(round.price.toString()),
        balance: round.balance.toString(),
        tokensSold: round.tokensSold.toNumber(),
      }));


      setRoundData({
        round: rounds?.find(item => item?.active === true),
        price: rounds?.findIndex(item => item?.active === true),
      })

      setRound1Data({
        price: rounds[0].price,
        balance: rounds[0].balance,
        raise: (rounds[0].balance * rounds[0].price),
        tokensSold: rounds[0].tokensSold,
      });
      setRound2Data({
        price: rounds[1].price,
        balance: rounds[1].balance,
        raise: (rounds[1].balance * rounds[1].price),
        tokensSold: rounds[1].tokensSold,
      });
      setRound3Data({
        price: rounds[2].price,
        balance: rounds[2].balance,
        raise: (rounds[2].balance * rounds[2].price),
        tokensSold: rounds[2].tokensSold,
      });

      const totalTokens = rounds[0].tokensSold + rounds[1].tokensSold + rounds[2].tokensSold;
      const totalRaisedAmount = (rounds[0].tokensSold * rounds[0].price)
        + (rounds[1].tokensSold * rounds[1].price)
        + (rounds[2].tokensSold * rounds[2].price);

      setTotalTokensSold(totalTokens);
      setTotalRaised(totalRaisedAmount);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUsdChange = (inputValue) => {
    const usd = inputValue;
    setAmount(inputValue);
    if (!inputValue) {
      setAmountError("Please enter an amount");
    } else {
      setAmountError('')
    }
    let price = 0;
    console.log("KANA : ", round);
    if (round === "round1") {
      price = round1Data.price;
    } else if (round === "round2") {
      price = round2Data.price;
    } else if (round === "round3") {
      price = round3Data.price;
    }

    if (usd && price) {
      console.log("DADADAD : ", usd, price, (usd / price))
      setTokenValue((usd / price));
    } else {
      setTokenValue("");
    }
  };

  const handleTokenPurchase = async () => {
    try {
      if (!wallet.connected || !token) {
        alert("Please connect your wallet");
      } else if (!amount) {
        return setAmountError("Please enter an amount");
      } else if (parseFloat(amount) == 0) {
        return setAmountError("Please enter a valid amount");
      }
      else {
        setLoading(true);
        const program = new Program(idl, programID, provider);
        const [statePDA] = await PublicKey.findProgramAddress([STATE_SEEDS], programID);
        const [vestingPDA] = await PublicKey.findProgramAddress(
          [VEST_SEEDS, Buffer.from([round]), wallet.publicKey.toBuffer()],
          programID
        );

        const buyerPaymentMintAta = getAssociatedTokenAddressSync(paymentMint, wallet.publicKey);
        const buyerTokenAta = getAssociatedTokenAddressSync(tokenMint, wallet.publicKey);

        const transaction = new web3.Transaction();

        const buyerPaymentMintAtaInfo = await connection.getAccountInfo(buyerPaymentMintAta);
        if (!buyerPaymentMintAtaInfo) {
          const createBuyerPaymentAtaIx = createAssociatedTokenAccountInstruction(
            wallet.publicKey,
            buyerPaymentMintAta,
            wallet.publicKey,
            paymentMint
          );
          transaction.add(createBuyerPaymentAtaIx);
        }

        const buyerTokenAtaInfo = await connection.getAccountInfo(buyerTokenAta);
        if (!buyerTokenAtaInfo) {
          const createBuyerTokenAtaIx = createAssociatedTokenAccountInstruction(
            wallet.publicKey,
            buyerTokenAta,
            wallet.publicKey,
            tokenMint
          );
          transaction.add(createBuyerTokenAtaIx);
        }

        const adminAta = getAssociatedTokenAddressSync(paymentMint, ADMIN_PUBLIC_KEY);
        const adminAtaInfo = await connection.getAccountInfo(adminAta);
        if (!adminAtaInfo) {
          const createAdminAtaIx = createAssociatedTokenAccountInstruction(
            wallet.publicKey,
            adminAta,
            ADMIN_PUBLIC_KEY,
            paymentMint
          );

          transaction.add(createAdminAtaIx);
        }


        const purchaseIx = await program.methods.purchasenow(new BN(amount * 1000_000), round).accounts({
          admin: ADMIN_PUBLIC_KEY,
          adminAta: adminAta,
          state: statePDA,
          mint: tokenMint,
          paymentMint: paymentMint,
          buyer: wallet.publicKey,
          buyerPaymentMintAta: buyerPaymentMintAta,
          vesting: vestingPDA,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        }).instruction();

        transaction.add(purchaseIx);

        const signature = await provider.sendAndConfirm(transaction);
        console.log('Purchase complete. Transaction Signature:', signature);

        PagesIndex.toast.success("Transaction added successfully");
        setAmount("");
        setTokenValue('');
        setLoading(false);
        setTimeout(() => {
          window?.location?.reload();
        }, 10000);
      }
    } catch (error) {
      setLoading(false);
      PagesIndex.toast.error(error?.message)
      console.log(error, "error");
    }
  };


  let currentPrice = 0;
  let nextPrice = "";

  if (round === "round1") {
    currentPrice = round1Data.price;
    nextPrice = round2Data.price;
  } else if (round === "round2") {
    currentPrice = round2Data.price;
    nextPrice = round3Data.price;
  } else if (round === "round3") {
    currentPrice = round3Data.price;
    nextPrice = "0.01";
  }


  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));


  //dynamic timer
  const calculateTimeLeft = () => {
    const now = new Date();
    const distance = targetDate - now;

    if (distance < 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const padZero = (num) => num.toString().padStart(2, '0');
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);


  return (

    <>
      <Index.Box className="hero-one-slider-images">
        <Index.Box className="hero-one-sec">
          <Index.Box className="container">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="one-herosection-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 0, sm: 0, md: 2, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 6",
                    lg: "span 7",
                  }}
                  className="grid-column order2"
                >
                  <Index.Box className="grid-col">
                    <Index.Box class="hero-one-slider-details">
                      <motion.div
                        animate={{ y: 100, opacity: 0 }}
                        transition={{ duration: 0.8 }}
                        whileInView={{ y: 0, opacity: 1 }}
                      >
                        <Index.Typography
                          variant="h4"
                          component="h4"
                          className="hero-one-slider-heading "
                        >
                          {socialData?.header}
                        </Index.Typography>
                      </motion.div>
                      <motion.div
                        animate={{ y: 100, opacity: 0 }}
                        transition={{ duration: 0.8 }}
                        whileInView={{ y: 0, opacity: 1 }}
                      >
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="hero-one-slider-para "
                        >
                          {socialData?.description}
                        </Index.Typography>
                      </motion.div>

                      <Index.Box className="hero-btn-main">
                        <motion.div
                          animate={{ y: 100, opacity: 0 }}
                          transition={{ duration: 0.8 }}
                          whileInView={{ y: 0, opacity: 1 }}
                        >
                          <Index.Box className="hero-btn-flex">
                            <a href={`${PagesIndex?.ImageURL}${socialData?.pdf}`} download target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }} >
                              <Index.GradientButton
                                className="gredient-btn hero-one-mint-btn"
                                btnLabel="White Paper"

                              />
                            </a>
                          </Index.Box>
                        </motion.div>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 6",
                    lg: "span 5",
                  }}
                  className="grid-column order1"
                >
                  <Index.Box className="grid-col">
                    <motion.div
                      animate={{ y: 100, opacity: 0 }}
                      transition={{ duration: 0.8 }}
                      whileInView={{ y: 0, opacity: 1 }}
                    >
                      <Index.Box className="presale-box">
                        <img src={Index.Svg.logo} className="logo-img" alt="logo" />
                        <Index.Box className="timer-main">
                          <Index.Box className="timer-flex">
                            <Index.Box className="timer-count-mian">
                              <Index.Box className="timer-box">
                                <Index.Typography className="timer-count">
                                  {timeLeft ? padZero(timeLeft?.days) : '00'}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Typography className="timer-text">
                                Days
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="timer-count-mian">
                              <Index.Box className="timer-box">
                                <Index.Typography className="timer-count">
                                  {timeLeft ? padZero(timeLeft?.hours) : '00'}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Typography className="timer-text">
                                Hours
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="timer-count-mian">
                              <Index.Box className="timer-box">
                                <Index.Typography className="timer-count">
                                  {timeLeft ? padZero(timeLeft?.minutes) : '00'}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Typography className="timer-text">
                                Minutes
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="timer-count-mian">
                              <Index.Box className="timer-box">
                                <Index.Typography className="timer-count">
                                  {timeLeft ? padZero(timeLeft?.seconds) : '00'}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Typography className="timer-text">
                                Seconds
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="presale-haeder">
                          <Index.Box className="presale-box-flex">
                            <Index.Typography className="presale-pay-text">

                              {`Round ${roundData?.price + 1}`}
                            </Index.Typography>
                            <Index.Typography className="presale-pay-text">
                              1 DWS = {roundData?.round?.price} USDT
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="presale-content-main">
                          <Index.Box className="presale-input-flex">
                            <Index.Box className="input-box presale-input-box">
                              <Index.Box className="form-group amount-input-purchase">
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder="Amount (in USDT)"
                                  value={amount}
                                  inputProps={{ maxLength: 12 }}
                                  type="string"
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const regex = /^(\d+)?(\.\d{0,3})?$/;
                                    if (regex?.test(inputValue) && inputValue !== "00" && inputValue !== ".") {
                                      handleUsdChange(inputValue)
                                    }
                                  }
                                  }
                                />
                                <Index.Box className="amount-error-box">
                                  <Index.Typography >{amountError ? amountError : ""}</Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            {/* <Index.Box className="presale-input-value-box ">
                              {amount && amount != "." ? tokenValue : "000"}
                            </Index.Box> */}
                            <Index.Box className="form-group amount-input-purchase">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Token"
                                value={tokenValue}
                                type="string"
                              />
                            </Index.Box>
                          </Index.Box>

                          <Index.Typography className="presale-price">
                            Next Stage price = {nextPrice} USDT
                          </Index.Typography>

                          <Index.Box className="admin-progress-bar-content">
                            <Index.Box className="admin-progress-bar-main">
                              <BorderLinearProgress
                                variant="determinate"
                                value={(parseFloat(totalTokensSold) / (parseFloat(totalTokensSold) + parseFloat(round1Data.balance) + parseFloat(round2Data.balance) + parseFloat(round3Data.balance))) * 100}
                                className="admin-progress-bar"
                              />
                              <span className="admin-progress-bar-text">
                                {((parseFloat(totalTokensSold) / (parseFloat(totalTokensSold) + parseFloat(round1Data.balance) + parseFloat(round2Data.balance) + parseFloat(round3Data.balance))) * 100).toFixed(2)}%
                              </span>
                            </Index.Box>
                          </Index.Box>
                          <Index.Typography className="presale-sold-text">
                            Sold - {(parseFloat(totalTokensSold) / 10 ** 9)} / {((parseFloat(totalTokensSold) + parseFloat(round1Data.balance) + parseFloat(round2Data.balance) + parseFloat(round3Data.balance)) / 10 ** 9)}
                          </Index.Typography>
                          <Index.Typography className="presale-sold-text">
                            Raised - ${(totalRaised / 10 ** 9)} / ${((totalRaised + parseFloat(round1Data.raise) + parseFloat(round2Data.raise) + parseFloat(round3Data.raise)) / 10 ** 9)}
                          </Index.Typography>
                          <Index.Box className="presale-btn-main">
                            <Index.BorderButton
                              className="presale-btn border-btn hero-section-btn"
                              btnLabel="from VH USDT Buy"
                              onClick={handleTokenPurchase}
                              loading={loading}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </motion.div>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
