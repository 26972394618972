



import information from './images/png/information.png'
import analytics from './images/png/analytics.png'
import Tokenomics from './images/png/tokenomics.png'
import FeatureRoundImg from './images/png/feature-round-img.png'
import oneFeaturesCard1 from './images/png/one-features-card-1.png'
import oneFeaturesCard2 from './images/png/one-features-card-2.png'
import oneFeaturesCard3 from './images/png/one-features-card-3.png'
import oneFeaturesCard4 from './images/png/one-features-card-4.png'
import oneFeatureTriangle1 from './images/png/oneFeatureTriangle1.png'
import oneFeatureTriangle2 from './images/png/oneFeatureTriangle2.png'
import oneFeatureFillTriangle from './images/png/oneFeatureFillTriangle.png'
import arrowright from './images/png/arrow-right.png'
import menu from './images/png/menu.png'
import DogImg from './images/png/aboutdog.png'
import adminlogo from './images/png/admin-logo.png'
import profileIcon from "./images/png/pen.png"
import pagenotfound from './images/png/pagenotfound.png'
import phantom from './images/png/phantom.png'


const Png = {
  phantom,
  information,
  Tokenomics,
  adminlogo,
  pagenotfound,
  profileIcon,
  FeatureRoundImg,
  oneFeaturesCard1,
  oneFeaturesCard2,
  oneFeaturesCard3,
  oneFeaturesCard4,
  oneFeatureTriangle1,
  oneFeatureTriangle2,
  oneFeatureFillTriangle,
  arrowright,
  menu,
  analytics,
  DogImg,
}

export default Png;