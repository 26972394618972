import React from 'react'
import Index from '../../Index'
import { PagesIndex } from '../../../container/PagesIndex'

export default function BorderButton(props) {
  return (
    <>
      <Index.Box className='border-btn-main'>
        <Index.Button className={props.className} onClick={props.onClick} type='submit' disableRipple disabled={props?.loading}>
          {props?.loading ? (
            <Index.Button >
            <PagesIndex.ButtonLoader color="white"  loading={props?.loading} />
            </Index.Button>
          ) :
            props.btnLabel
          }
        </Index.Button>
      </Index.Box>


      {/* use this button like below demo */}
      {/* <Index.BorderButton className="border-btn" btnLabel="View Button"/> */}</>
  )
}
