import React, { useState } from "react";
import "./resetPassword.css";
import Index from "../../../Index";
import { PagesIndex } from "../../../PagesIndex";

const ResetPassword = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = PagesIndex.useNavigate();
  let adminEmail = localStorage.getItem("adminEmail");
  let initialValues = {
    password: "",
    confirm_password: "",
  };

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const data = {
        email: adminEmail,
        password: values.password,
      };
      const res = await PagesIndex.adminResetPassword(data);
      if (res?.data?.status === 200) {
        navigate("/admin");
      }
    } catch (error) {}
    setLoading(false);
  };
  return (
    <>
      <Index.Box className="admin-main admin-main-bg">
        <Index.Box className="login-section">
          <Index.Box className="admin-main-bg">
            <Index.Box className="auth-main">
              <Index.Box className="auth-right">
                <Index.Box className="auth-common-box">
                  <Index.Box className="auth-header">
                    <Index.Box className="logo-name">
                      {/* <img
                      src={PagesIndex.Png.LogoName}
                      alt="logo"
                      className=""
                    /> */}
                    </Index.Box>
                    <Index.Box className="auth-title auth-logo-title">
                      Reset Password
                    </Index.Box>
                    {/* <Index.Box className="auth-subtitle">
                    Please enter your new password
                  </Index.Box> */}
                  </Index.Box>

                  <PagesIndex.Formik
                    // enableReinitialize
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={PagesIndex.AdminResetPasswordSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Index.Stack
                        component="form"
                        spacing={2}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                      >
                        <Index.Box className="auth-body">
                          <Index.Grid container spacing={3}>
                            <Index.Grid item xs={12}>
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="form-lable">
                                  Enter your new password
                                </Index.FormHelperText>
                                <Index.TextField
                                  fullWidth
                                  className="form-input login-password"
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  placeholder="Enter your new password"
                                  name="password"
                                  type={showNewPassword ? "text" : "password"}
                                  autoComplete="off"
                                  inputProps={{
                                    className: "input_props",
                                    maxLength: 33,
                                  }}
                                  InputLabelProps={{
                                    className: "add-formlabel",
                                  }}
                                  FormHelperTextProps={{
                                    className: "input_label_props",
                                  }}
                                  onBlur={handleBlur}
                                  value={values.password}
                                  onChange={handleChange}
                                  helperText={
                                    touched.password && errors.password
                                  }
                                  error={Boolean(
                                    errors.password && touched.password
                                  )}
                                  InputProps={{
                                    endAdornment: (
                                      <Index.InputAdornment position="end">
                                        <Index.IconButton
                                          className="new-password-toggle password-icon"
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowNewPassword}
                                          edge="end"
                                        >
                                          {showNewPassword ? (
                                            <Index.Visibility />
                                          ) : (
                                            <Index.VisibilityOff />
                                          )}
                                        </Index.IconButton>
                                      </Index.InputAdornment>
                                    ),
                                  }}
                                />
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12}>
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="form-lable">
                                  Enter your confirm password
                                </Index.FormHelperText>
                                <Index.TextField
                                  fullWidth
                                  className="form-input login-password"
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  placeholder="Enter your confirm password"
                                  name="confirm_password"
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  autoComplete="off"
                                  inputProps={{
                                    className: "input_props",
                                    maxLength: 32,
                                  }}
                                  InputLabelProps={{
                                    className: "add-formlabel",
                                  }}
                                  FormHelperTextProps={{
                                    className: "input_label_props",
                                  }}
                                  onBlur={handleBlur}
                                  value={values.confirm_password}
                                  onChange={handleChange}
                                  helperText={
                                    touched.confirm_password &&
                                    errors.confirm_password
                                  }
                                  error={Boolean(
                                    errors.confirm_password &&
                                      touched.confirm_password
                                  )}
                                  sx={{ mb: 3 }}
                                  InputProps={{
                                    endAdornment: (
                                      <Index.InputAdornment position="end">
                                        <Index.IconButton
                                          className="confirm-password-toggle password-icon"
                                          aria-label="toggle password visibility"
                                          onClick={
                                            handleClickShowConfirmPassword
                                          }
                                          edge="end"
                                        >
                                          {showConfirmPassword ? (
                                            <Index.Visibility />
                                          ) : (
                                            <Index.VisibilityOff />
                                          )}
                                        </Index.IconButton>
                                      </Index.InputAdornment>
                                    ),
                                  }}
                                />
                              </Index.Box>
                            </Index.Grid>

                            {/* Button */}
                            <Index.Grid item xs={12}>
                              <Index.Box className="auth-btn-box border-btn-main">
                                <Index.Button
                                  variant="contained"
                                  className="border-btn custom-btn-login"
                                  type={loading ? "button" : "submit"}
                                  disableRipple
                                >
                                  {loading ? (
                                    <PagesIndex.AdminButtonLoader />
                                  ) : (
                                    "Submit"
                                  )}
                                </Index.Button>
                              </Index.Box>
                            </Index.Grid>
                          </Index.Grid>
                        </Index.Box>
                      </Index.Stack>
                    )}
                  </PagesIndex.Formik>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ResetPassword;
