import { PagesIndex } from "../../../container/PagesIndex";

export const userWalletLoginAndRegister=async (data)=>{
  try {
      const response = await PagesIndex.DataService.post(PagesIndex.Api.Auth.USER_REGISTER, data);
      if (response?.data?.status === 201 ||200) {
    localStorage.setItem("userToken",response?.data?.data?.token)
    PagesIndex.DataService.defaults.headers.common.auth = response?.data?.data?.token;
    return response.data;
  }
  } catch (error) {
    PagesIndex.toast.error(error?.response?.data?.message, { autoClose: 2000 });
      console.log("error",error);
  }
}