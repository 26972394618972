import React, { useEffect, useState,useRef } from "react";
import Index from "../../../Index";
import debounce from 'lodash.debounce';
import "./analytics.css";
import { PagesIndex } from "../../../PagesIndex";

const Analytics = () => {
  const [loading, setLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState({});
  const [iconPreview, setIconPreview] = useState(null);
  const descriptionRef = useRef(null);
 
  const getAnaylitcsData = async () => {
    try {
      const res = await PagesIndex.adminAnaylitcsData();
      if (res?.data?.status === 200) {
        setAnalyticsData(res?.data?.data[0]);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getAnaylitcsData();
  }, []);

  const initialValues = {
    image: analyticsData ? analyticsData?.image : "",
    description: analyticsData ? analyticsData?.description : "",
  };

 

  const informationFormSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    if(analyticsData?._id){
      formData.append("id", analyticsData?._id);
    }
    formData.append("Image", values?.image);
    formData.append("description", values.description);

    try {
      const res = await PagesIndex.adminAnaylitcsUpdate(formData);
      if (res?.data?.status === 200 || 201) {
        getAnaylitcsData();
      }
    } catch (error) {
      console.log(error, "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (analyticsData && analyticsData.image) {
      setIconPreview(`${PagesIndex.ImageURL}${analyticsData?.image}`);
    }
  }, [analyticsData]);

  useEffect(() => {
    const handleResize = debounce(() => {
      console.log('Resized:', descriptionRef.current?.offsetHeight);
    }, 100);

    const observer = new ResizeObserver(handleResize);
    const currentRef = descriptionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <Index.Box className="presale-userlist">
        <Index.Box className="user-heading-box">
          <Index.Box className="user-heading">Public Analytics</Index.Box>
        </Index.Box>

        <Index.Box className="admin-edit-profile-main">
          <Index.Box className="admin-edit-profile-box social-main-box section-box">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={informationFormSubmit}
              initialValues={initialValues}
              validationSchema={PagesIndex.AdminAnalyticsSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
              }) => (
                <form
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box>
                    <Index.Box className="edit-profile-content">
                      <Index.Box className="auth-body social-form-box feature-form-box">
                      {iconPreview && ( 
                                <img
                                  src={iconPreview}
                                  alt="Icon Preview"
                                  className="icon-preview"
                                />
                              )}
                        <Index.Grid container spacing={3}>
                          <Index.Grid item xs={12} sm={12} md={6} lg={6} >
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                              Analytics Image*
                              </Index.Typography>
                              <Index.TextField
                                fullWidth
                                type="file"
                                hiddenLabel
                                title=""
                                id="filled-hidden-label-normal"
                                placeholder="White paper"
                                size="small"
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="image"
                                data-testid="email-input"
                                inputProps={{
                                  accept: "image/jpeg,image/jpg, image/png",
                                }}
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                onChange={(e) => {
                                  const file = e.currentTarget.files[0];
                                  const allowedFormats = [
                                    "image/jpeg",
                                    "image/jpg",
                                    "image/png",
                                  ];
                                  if (
                                    file &&
                                    !allowedFormats.includes(file.type)
                                  ) {
                                    e.currentTarget.value = "";
                                    // setFieldValue("image", "");
                                    return;
                                  }
                                  const reader = new FileReader();
                                  reader.onloadend = () => {
                                    setIconPreview(reader.result);
                                  };
                                  if (file) {
                                    reader.readAsDataURL(file);
                                  }
                                  setFieldValue("image", file);
                                }}
                                // helperText={touched.image && errors.image}
                                // error={Boolean(errors.image && touched.image)}
                              />
                              {!analyticsData?.image && !values?.image &&
                              <Index.Typography className="error-message">{errors?.image}</Index.Typography>
                                         }
                            </Index.Box>
                          </Index.Grid>


                          <Index.Grid item xs={12}>
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                                Description*
                              </Index.Typography>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Description"
                                size="small"
                                multiline
                                rows={4}
                                maxRows={4}
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="description"
                                data-testid="mobileNumber-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                inputProps={{ maxLength: 512 }}
                                value={values.description}
                                onChange={handleChange}
                                ref={descriptionRef}
                                helperText={
                                  touched.description && errors.description
                                }
                                error={Boolean(
                                  errors.description && touched.description
                                )}
                              />
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                        <Index.Box className="edit-btn-box">
                          <Index.Button
                            type={loading ? "button" : "submit"}
                            data-testid="button"
                            variant="contained"
                            className="edit-btn"
                          >
                            {loading ? (
                              <PagesIndex.AdminButtonLoader />
                            ) : (
                              <>
                              <Index.SaveAsIcon fontSize="small"/>
                              Submit
                              </>
                            )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Analytics;
