import './App.css';
import Routes from './routes/Routes'
import './assets/style/style.css';
import './assets/style/responsive.css';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import '@solana/wallet-adapter-react-ui/styles.css';


function App() {
  const network = "devnet"; 

  const wallets = [
    new PhantomWalletAdapter()
  ];
  return (
    <ConnectionProvider endpoint={`https://api.${network}.solana.com`}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
      <Routes />
      </WalletModalProvider>
        <ToastContainer/>
    </WalletProvider>
    </ConnectionProvider>
  );
}
export default App;


