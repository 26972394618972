import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import "./header.css";
import { PagesIndex } from "../../../../container/PagesIndex";
import { motion } from "framer-motion";
require("@solana/wallet-adapter-react-ui/styles.css");

export default function Header() {
  const { publicKey, connected, disconnect } = PagesIndex.useWallet();
  let token = localStorage.getItem("userToken");
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const [flag, setFlag] = useState(true);
  const [open, setOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [loading, setLoading] = useState(false);

  const handleScrollToDiv = (id) => {
    setTimeout(() => {
      document?.getElementById(id)?.scrollIntoView({
        behavior: "smooth",
      });
    });
  };
  useEffect(() => {
    if (location.pathname === "/my-account") {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);
  const handleOpenUserMenu = (event) => {
    if (!Boolean(anchorElUser)) {
      setAnchorElUser(event.currentTarget);
    }
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfile = () => {
    navigate("/my-account");
    handleCloseUserMenu();
  };

  const formatPublicKey = (publicKey) => {
    const keyString = publicKey.toBase58();
    return `${keyString.slice(0, 4)}...${keyString.slice(-4)}`;
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await PagesIndex.userWalletLoginAndRegister({
        walletAddress: publicKey?.toBase58(),
      });
      if (res.status === 200 || 201) {
        console.log(res?.data);
      } else {
        disconnect();
      }
    } catch (error) {
      disconnect();
      console.log(error, "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (connected && !token) {
        handleSubmit();
      }
      if (!connected) {
        localStorage.removeItem("userToken");
        navigate("/");
      }
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, [connected, token]);

  return (
    <>
      <Index.Box
        onClick={() => {
          document.body.classList[!open ? "add" : "remove"]("header-override");
          setOpen((e) => !e);
        }}
        class={`overlay-section mobile-show ${
          open ? "overlay-mobile-active" : ""
        }`}
        id="overlays"
      >
        {" "}
      </Index.Box>
      <Index.Box className="header-comman-main">
        <Index.Box className="container">
          <Index.Box className="header-row-common">
            <Index.Box className="header-logo-com">
              <motion.div
                animate={{ y: 100, opacity: 0 }}
                transition={{ duration: 0.8 }}
                whileInView={{ y: 0, opacity: 1 }}
              >
               { location.pathname !== "/" ?
                <Index.Link href="#" className="header-refreal-link" to="/">
               <PagesIndex.LightTooltip title="Home">
                  <img
                    src={Index.Svg.logo}
                    className="logo-brand-header"
                    alt="logo"
                  />
                </PagesIndex.LightTooltip> 
                </Index.Link> :
                    <img
                    src={Index.Svg.logo}
                    className="logo-brand-header"
                    alt="logo"
                  />}
              </motion.div>
            </Index.Box>

            <Index.Box
              className={`header-list-light-details ${
                open ? "header-left-active" : ""
              }`}
            >
              <Index.List className="header-list-light">
                <Index.ListItem
                  onClick={() => handleScrollToDiv("section-1")}
                  className="header-listitem-light"
                >
                  <motion.div
                    animate={{ y: 100, opacity: 0 }}
                    transition={{ duration: 0.8 }}
                    whileInView={{ y: 0, opacity: 1 }}
                  >
                    <Index.Link className="list-header-link" to="/">
                      Information
                    </Index.Link>
                  </motion.div>
                </Index.ListItem>
                <Index.ListItem
                  onClick={() => {
                    handleScrollToDiv("section-2");
                  }}
                  className="header-listitem-light"
                >
                  <motion.div
                    animate={{ y: 150, opacity: 0 }}
                    transition={{ duration: 0.8 }}
                    whileInView={{ y: 0, opacity: 1 }}
                  >
                    <Index.Link className="list-header-link" to="/">
                      Features
                    </Index.Link>
                  </motion.div>
                </Index.ListItem>
                <Index.ListItem
                  onClick={() => handleScrollToDiv("section-3")}
                  className="header-listitem-light"
                >
                  <motion.div
                    animate={{ y: 200, opacity: 0 }}
                    transition={{ duration: 0.8 }}
                    whileInView={{ y: 0, opacity: 1 }}
                  >
                    <Index.Link
                      className="list-header-link"
                      onClick={() => setFlag(!flag)}
                      to="/"
                    >
                      Analytics
                    </Index.Link>
                  </motion.div>
                </Index.ListItem>
                <Index.ListItem
                  onClick={() => handleScrollToDiv("section-4")}
                  className="header-listitem-light"
                >
                  <motion.div
                    animate={{ y: 250, opacity: 0 }}
                    transition={{ duration: 0.8 }}
                    whileInView={{ y: 0, opacity: 1 }}
                  >
                    <Index.Link className="list-header-link" to="/">
                      Roadmap
                    </Index.Link>
                  </motion.div>
                </Index.ListItem>
                <Index.ListItem
                  onClick={() => handleScrollToDiv("section-5")}
                  className="header-listitem-light"
                >
                  <motion.div
                    animate={{ y: 250, opacity: 0 }}
                    transition={{ duration: 0.8 }}
                    whileInView={{ y: 0, opacity: 1 }}
                  >
                    <Index.Link className="list-header-link" to="/">
                      Tokenomics
                    </Index.Link>
                  </motion.div>
                </Index.ListItem>
              </Index.List>
            </Index.Box>

            <Index.Box className="header-right custom-header-right">
              <PagesIndex.WalletMultiButton disabled={loading}>
                <Index.Box className="main-connect">
                  <img className="connect" src={PagesIndex.Png.phantom}></img>
                </Index.Box>
                {connected && publicKey && token && token !== "undefined"
                  ? formatPublicKey(publicKey)
                  : "Connect"}
              </PagesIndex.WalletMultiButton>

              {connected && publicKey && token && token !== "undefined" && (
                <Index.Box sx={{ flexGrow: 0 }} className="profile-icon-main">
                  <PagesIndex.LightTooltip title="Account">
                    <Index.IconButton
                      // onClick={handleOpenUserMenu}
                      sx={{ p: 0 }}
                      onClick={handleProfile}
                    >
                      <Index.AccountCircleIcon className="profile-icon" />
                    </Index.IconButton>
                  </PagesIndex.LightTooltip>
                  {/* <Index.Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    className="profile-menu"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    // keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <Index.MenuItem
                      className="profile-menu-option"
                      onClick={handleProfile}
                    >
                      <Index.Typography textAlign="center">
                        Profile
                      </Index.Typography>
                    </Index.MenuItem>
                  </Index.Menu> */}
                </Index.Box>
              )}

              <Index.Box className="burgur-btn-mobile">
                <Index.Button
                  onClick={() => {
                    document.body.classList[!open ? "add" : "remove"](
                      "header-override"
                    );
                    setOpen((e) => !e);
                  }}
                  className="gredient-btn-menu"
                >
                  <img
                    src={Index.Png.menu}
                    className="menu-icon-mobile"
                    alt="logo"
                  />
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
