import React, { useEffect, useState } from 'react';
import Index from '../../../../Index';
import './featurese.css';
import { PagesIndex } from '../../../../../container/PagesIndex';
import { motion } from "framer-motion";

export default function FeaturesOne() {
  const [featureData, setFeatureData] = useState([]);
  const [expandedFeature, setExpandedFeature] = useState(null);
  const oneFeatureCarousel = {
    oneFeatureCarousel: {
      0: {
        items: 1,
        margin: 10,
      },
      768: {
        items: 2,
        margin: 15,
      },
      1024: {
        items: 3,
      },
      1200: {
        items: 3,
      }
    }
  }


  const getFeatureData = async () => {
    try {
      const res = await PagesIndex.featuresData();
      if (res?.data?.status === 200) {
        setFeatureData(res?.data?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getFeatureData();
  }, []);

  const toggleExpand = (index) => {
    setExpandedFeature(expandedFeature === index ? null : index);
  };

  const renderDescription = (description, index) => {
    if (!description) return '';
    if (expandedFeature === index) {
      return description;
    } else {
      return description?.slice(0, 240) + (description?.length > 240 ? '...' : '');
    }
  };


  return (
    <>
      <Index.Box className='featureone-main'>
        <Index.Box className='one-feature-bg'>
          <Index.Box className='container'>
            <Index.Box className='one-featureone-content icon-content-title-after'>
              <motion.div
                animate={{ y: 100, opacity: 0 }}
                transition={{ duration: 0.8 }}
                whileInView={{ y: 0, opacity: 1 }}
              >
                <Index.Box className='one-featureone-title info-title-icon info-light-title-main'>
                  <Index.Typography className='title-gradient icon-title-info info-titel-light' variant="h1">
                    Features
                  </Index.Typography>
                </Index.Box>
              </motion.div>
              <Index.Box className='one-featureone-slid-sec'>
                <Index.Box className="feature-one-caraousel-main">
                  <Index.OwlCarousel
                    responsive={oneFeatureCarousel.oneFeatureCarousel}
                    className="owl-theme feature-one-owl-carousel"
                    loop margin={30}
                    nav={true}
                    dots={false}
                    center={false}
                    navText={["<img src=" + Index.Svg.twoprevarrow + " >", "<img src=" + Index.Svg.twonextarrow + " >"]}
                  >
                    {featureData?.length && featureData?.map((item, index) => (
                      <div class="item" key={index}>
                        <Index.Box className="feature-one-main feature-one-card1">
                          <motion.div
                            animate={{ y: 100, opacity: 0 }}
                            transition={{ duration: 0.8 }}
                            whileInView={{ y: 0, opacity: 1 }}
                          >
                            <Index.Box className="one-feature-img-box">
                              <img src={`${PagesIndex.ImageURL}${item?.icon}`} className='one-feature-img' alt='logo' />
                            </Index.Box>
                          </motion.div>
                          <motion.div
                            animate={{ y: 150, opacity: 0 }}
                            transition={{ duration: 0.8 }}
                            whileInView={{ y: 0, opacity: 1 }}
                          >
                            <Index.Typography className='feature-one-card-title' variant="h6" component='h6'>
                              {item?.heading}
                            </Index.Typography>
                          </motion.div>
                          <motion.div
                            animate={{ y: 150, opacity: 0 }}
                            transition={{ duration: 0.8 }}
                            whileInView={{ y: 0, opacity: 1 }}
                          >
                            <Index.Typography className='feature-one-card-text' variant="p" component='p'>
                              {renderDescription(item?.description, index)}
                              {item?.description?.length > 240 && (
                                <span onClick={() => toggleExpand(index)} className='view-more-btn'>
                                  {expandedFeature === index ? ' View Less' : 'View More'}
                                </span>
                              )}
                            </Index.Typography>
                          </motion.div>
                        </Index.Box>
                      </div>
                    ))}
                  </Index.OwlCarousel>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
